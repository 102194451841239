.contact_top_section{
    position: relative;
    background-image: url('../../images/contacttop.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height:28rem;
    width: 100%;

}
.contact_top_heading{
    position: relative;
   
}
.contact_top_heading h1{
    color: #2C2024;
    text-align: center;
    padding: 8rem 0 0 1rem;
opacity: 1;
}
.navlink_homeandcontact{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}
.home_and_contact p{
    display: inline-block;
    text-align: center;
    color: #2C2024;  
}
.home_and_contact p:hover{
    color: #144D79;
    font-size: 1.1rem;
}
/* second section css */
.get_touch_section{
    margin-top: 2rem;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.get_touch_heading {
    margin-top: 5rem;
}
.get_touch_heading h2{
    font-size: 2rem;
    color: #144D79;

}
.get_touch_heading h3{
    font-size: 1.2rem;
    color: #2C2024;

}
.get_touch_heading p{

    color: #707070;
    padding-right: .5rem;

}
.form_sectio{
    margin-top: 5rem;
    position: relative;
    margin-left: 5rem;
    width: 26rem;
    height: 26rem;
    border-radius: 18px;
    background: #FEF6F6 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 20px #00000029;
opacity: 0.65;
}
.form_sectio h4{
    font-size: 1.2rem;
    padding: 2rem 0 .5rem 5rem;
    color: #2C2024;
 
}
.form_sectio p{
    padding: .2rem 0 .5rem 5rem;
    color: #706B70;
 
}
.form_section_div{
    margin: 1rem 0 0 5rem;
}
.contact_box{
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translate(-50%, -50%);
    background: #144D79 0% 0% no-repeat padding-box;
box-shadow: 6px 6px 20px #00000029;
border-radius: 25px;
opacity: 0.67;
width: 11rem;
height: 10rem;
}
.contact_box h1{
    padding: 1rem 0 0 0;
    text-align: center;
    font-size: 1.1rem;
    letter-spacing: 2.6px;
color: #FFFFFF;
opacity: 1;
  
}
.form-control {
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: 2rem .75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* color: #495057; */
    color: #fff !important;
    /* background-color: #fff; */
    background-clip: padding-box;
    border: none !important;
    outline: none !important;
    border-radius: 21px  !important;
    width: 19rem !important;
    background: #6E6978 0% 0% no-repeat padding-box !important;
border-radius: 21px;
opacity: 1;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
::placeholder {
    color:#fff !important;
  padding: 1rem 1rem !important;
}
.btn_submit2{
    outline: none !important;
    border: none !important;
    padding: 10px 25px !important;
    font-size: .8rem !important;
    color: #fff;
   border-radius:18px !important;
   box-shadow: 6px 6px 20px #00000029 !important;
   background: #144D79 0% 0% no-repeat padding-box !important;
}
.men_icon{
    position: absolute;
    top: 93%;
    left: 17%;
   
    transform: translate(-50%, -50%);
}
.men_img_bottom{
    position: relative;
    z-index:999;
    width: 3.5rem;
    height: 3.5rem;
}
.form_input_man{
   z-index: 1;
    position: absolute;
    top: 33.5%;
    left: 80%;
    transform: translate(-50%, -50%);
}
.user_img_bottom{
    color:#fff !important;
    width: 1rem;
    height: 1rem;
}
.email_icon{
   
     position: absolute;
     top: 46.5%;
     left: 80%;
     transform: translate(-50%, -50%);
 }
 .email_img_bottom{
    
     width: 1rem;
     height: 1rem;
 }
 input:-internal-autofill-selected {
    appearance: menulist-button;
    /* background-color: rgb(232, 240, 254) !important; */
    background-color: #6E6978 0% 0% no-repeat padding-box !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
}
.my_lists{
     font-size: .8rem;
     text-align: center;
     color: #fff;
     text-decoration: none !important;
     padding:0 0 .4rem 0;
 }
 .my_lists a{
    font-size: .8rem;
    text-align: center;
    color: #fff;
    text-decoration: none !important;
    padding:0 0 .4rem 0;
}
.my_lists a:hover{
 
    color: #144D79;
  
}

/* map section css */
.map_section_heading{
    margin-top: 5rem;
}
.map_section_heading h2{
    font-size: 2rem;
    color: #144D79;

}
.map_section_heading h3{
    font-size: 1.2rem;
    color: #2C2024;

}
.map_section_heading iframe{
    width: 100%; 
    height: 25rem; 
    margin-top:2rem;
}
@media screen and (max-width:800px){
    .contact_top_section{
        height:9rem;
       
    }
    .contact_top_heading h1{
        padding: 3rem 0 0 1rem;

    }
    /* second section css */
.get_touch_section{
    margin-top: 1rem;
  
}
.get_touch_heading {
    margin-top: 2rem;
}
.get_touch_heading h2{
    font-size: 2rem;
}
.form_sectio{
    margin-top: 10rem;
    position: relative;
    margin-left: 0;
    width: 100%;
    height: 28rem;
  
opacity: 0.95;
}
.form_sectio h4{
  
    padding: 2rem 0 .5rem 2rem;
  
 
}
.form_sectio p{
    padding: .2rem 0 .5rem 2rem;
    color: #706B70;
 
}
.form_section_div{
    margin: 1rem 0 0 2rem;
}

.contact_box{
    top: -13%;
    left: 50%;
opacity: 0.67;
width: 14rem;
height: 10rem;
}
.form-control {
  width: 18rem !important;
 
}
.form_input_man{
     top: 31.5%;
     left: 79%;
    
 }
 .email_icon{
    top: 46%;
    left: 79%;
}
.men_icon{
    top: 94%;
    left: 5%;
}
.men_img_bottom{
    position: relative;
    z-index:999;
    width: 3rem;
    height: 3rem;
}
#form_1222{
   width: 95% !important;
    padding:1.5rem 0 1.5rem 0 !important;
}
#exampleFormControlInput1222{
    width: 95% !important;
    padding:1.5rem 0 1.5rem 0 !important;
}
#exampleFormControlTextarea123232{
    width: 95% !important;
    padding:1.5rem 0 1.5rem 0 !important;
}
/* map section css */
.map_section_heading{
    margin-top: 3rem;
}
}