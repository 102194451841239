.product_top_section {
    position: relative;
    background-image: url("../../images/product.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 25rem;
    width: 100%;
  }
  .product_top_heading {
    position: relative;
  }
  .product_top_heading h1 {
    color: #fff;
    text-align: center;
    padding: 8rem 0 0 1rem;
    opacity: 1;
  }
  .navlink_homeandcontact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .home_and_product p {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .home_and_product p:hover {
    color: #fff;
    font-size: 1.1rem;
  }

  .blue-bg {
    background-color: #fff;
    color: #EE6060;
    height: 100%;
  }
  
  .circle {
    font-weight: bold;
    padding: 15px 20px;
    border-radius: 50%;
    background-color: #EE6060;
    color: #4D4545;
    max-height: 50px;
    z-index: 2;
  }
  
  .how-it-works.row {
    display: flex;
  }
  .how-it-works.row .col-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
  }
  .how-it-works.row .col-2::after {
    content: "";
    position: absolute;
    border-left: 3px solid #EE6060;
    z-index: 1;
  }
  .how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%;
  }
  .how-it-works.row .col-2.full::after {
    height: 100%;
    left: calc(50% - 3px);
  }
  .how-it-works.row .col-2.top::after {
    height: 50%;
    left: 50%;
    top: 0;
  }
  
  .timeline div {
    padding: 0;
    height: 40px;
  }
  .timeline hr {
    border-top: 3px solid #EE6060;
    margin: 0;
    top: 17px;
    position: relative;
  }
  .timeline .col-2 {
    display: flex;
    overflow: hidden;
  }
  .timeline .corner {
    border: 3px solid #EE6060;
    width: 100%;
    position: relative;
    border-radius: 15px;
  }
  .timeline .top-right {
    left: 50%;
    top: -50%;
  }
  .timeline .left-bottom {
    left: -50%;
    top: calc(50% - 3px);
  }
  .timeline .top-left {
    left: -50%;
    top: -50%;
  }
  .timeline .right-bottom {
    left: 48.5%;
    top: calc(50% - 3px);
  } 
  .indroducting_heading{
    padding: 3rem 0 0rem 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
    color: #144D79;
text-shadow: 0px 3px 6px #00000029;
border-bottom: 4px solid #EE6060;
  }
  .all_product_headings h5{
    font-size: 1rem;
    width: 100%;
  }
  .box_products{
    position: relative;
  }
  .product_img_box{
    width:100%;
    height:18rem;
    margin-top: .5rem;
  }
  .box_products2{
    position: relative;
  }
  .product_img_box2{
    width:100%;
    height:18rem;
    margin-top: .5rem;
  }
  .all_product_services_box{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top: 15%;
    left: 2%;
  background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url('../../images/1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
    /* background-color:rgba(0,0,0,0.78); */
    /* opacity: .66; */
  }
  .all_product_services_box2{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top:15%;
    left: 10%;
    background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url('../../images/4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-color:rgba(0,0,0,0.78); */
    /* opacity: .66; */
  }
  .all_product_services_box3{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top: 15%;
    left: 2%;
  background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url('../../images/3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  }
  .all_product_services_box4{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top:15%;
    left: 10%;
    background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url('../../images/2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .all_product_services_box h3,
  .all_product_services_box2 h3,
  .all_product_services_box3 h3,
  .all_product_services_box4 h3
 {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    padding-top:7rem;
    text-transform: capitalize;
    font-weight: bold;
  }
  .all_product_services_box p,
  .all_product_services_box2 p,
  .all_product_services_box3 p,
  .all_product_services_box4 p
 {
    font-size: .8rem;
    color: #fff;
    text-align: center;
    
  }
  .btn_product_read{
    background: none;
    border: none;
    margin-left: 13rem;
    font-size: .8rem;
    color: #ED1A3B;
text-transform: uppercase;
opacity: 1;
font-weight: bold;
  }
  .btn_product_read1{
    background: none;
    border: none;
    margin-right: 12rem;
    font-size: .8rem;
    color: #ED1A3B;
text-transform: uppercase;
font-weight: bold;
opacity: 1;
  }
  @media screen and (max-width:800px){
    .product_top_section {
      height: 15rem;
    }
    .product_top_heading {
      position: relative;
    }
    .product_top_heading h1 {
      padding: 4rem 0 0 1rem;
    }
    .indroducting_heading{
      padding: 3rem 0 0rem 0;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      text-align: center;
      color: #144D79;
text-shadow: 0px 3px 6px #00000029;
border-bottom: 4px solid #EE6060;
    }
    .all_product_headings h5{
      font-size: 1rem;
      width: 100%;
    }
    .box_products{
      position: relative;
    }
    .box_products2{
      position: relative;
    }
  
    .product_img_box{
      width:16rem;
      height:12rem;
      margin-top: .5rem;
    }
    .product_img_box2{
      width:11rem;
      height:12rem;
      margin-top: .5rem;
    }
    .all_product_services_box{
      width: 14.5rem;
      height: 9.5rem;
      top: 15%;
      left: 1%;
     
    }
    .all_product_services_box3{
      width: 14.5rem;
      height: 9.5rem;
      top: 15%;
      left: 1%;
     
    }
    .all_product_services_box5{
      width: 14.5rem;
      height: 9.5rem;
      top: 15%;
      left: 1%;
     
    }
    .all_product_services_box2{
      width: 17rem;
      height: 12rem;
      top: 4%;
      left: -60%;
      background-color:rgba(0,0,0,0.68);
      /* opacity: .66; */
    }
    .all_product_services_box4{
      width: 17rem;
      height: 12rem;
      top: 4%;
      left: -60%;
      background-color:rgba(0,0,0,0.68);
      /* opacity: .66; */
    }
    .all_product_services_box6{
      width: 17rem;
      height: 12rem;
      top: 4%;
      left: -60%;
      background-color:rgba(0,0,0,0.68);
      /* opacity: .66; */
    }
    .dot{
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background:#fff;
      margin: 2rem 0 0 7rem;
    }
    .dot1{
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background:#fff;
      margin: 2rem 6rem 0 0;
    }
    .all_product_services_box h3,
    .all_product_services_box2 h3,
    .all_product_services_box3 h3,
    .all_product_services_box4 h3
    {
      font-size: 1rem;
      color: #fff;
      text-align: center;
      padding-top:3rem;
      text-transform: capitalize;
    }
    .all_product_services_box p,
  .all_product_services_box2 p,
  .all_product_services_box3 p,
  .all_product_services_box4 p
  {
      font-size: .6rem;
      color: #fff;
      text-align: center;
      padding: 0 .1rem 0 .1rem;
      
    }
    .btn_product_read{
      background: none;
      border: none;
      margin-left:5rem;
      font-size: .8rem;
      color: #ED1A3B;
  text-transform: uppercase;
  opacity: 1;
    }
    .btn_product_read1{
      background: none;
      border: none;
      margin-right: 5rem;
      font-size: .8rem;
      color: #ED1A3B;
  text-transform: uppercase;
  opacity: 1;
    }
  }