.blog_top_section {
    position: relative;
    background-image: url("../../images/Blog.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 25rem;
    width: 100%;
  }
  .blog_top_heading {
    position: relative;
  }
  .blog_top_heading h1 {
    color: #2C2024;
    text-align: center;
    padding: 8rem 0 0 1rem;
    opacity: 1;
  }
  .navlink_homeandcontact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .home_and_blog p {
    display: inline-block;
    text-align: center;
    color: #2C2024;
  }
  .home_and_blog p:hover {
    color: #2C2024;
    font-size: 1.1rem;
  }
  
  /* topics section css here */
  .second_top_blog{
    margin-top: 2rem;
  }
  .all_blog_latest{
    margin-top: 2rem;
  }
.all_blog_latest h1{
  font-size: 1.5rem;
  color: #144D79;
  text-shadow: 0px 3px 6px #00000029;
  padding-top: 3rem;

}
.all_blog_latest p{
  font-size: 1rem;
  color: #144D79;
 padding: 0rem 0 .5rem 0;

}
.explore_more_blog{
  outline: none !important;
  border: none;
  padding: 10px 25px ;
  font-size: .8rem ;
  color: #fff;
 border-radius:18px;
 box-shadow: 6px 6px 20px #00000029 ;
 background: #144D79 0% 0% no-repeat padding-box ;
 font-weight: bold;

}
.explore_more_blog:hover{
 background-color: #fff;
 color: #144D79;
 border: 1px solid #144D79;
 text-decoration: none !important;
 transition: .3s ease-in;
 
}
.blog_img_techno{
  margin-top: 5rem;
  width:100%;
  height: 60%;
}
 
/* blogvideo section css */

.welcome_blog h1{
  text-align: center;
  font-size: 2rem;
  color: #144D79;
  padding-top: 1rem;
}
.welcome_blog p{
  text-align: center;
  font-size: 1rem; 
}
.blog_video_section{
    margin-top: 2rem;
}
.blog_video_box{
    width: 100%;
    height: 25rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000003D;
  border-radius: 18px;
  opacity: 1; 
}
.blog_bottom_img {
    width: 100%;
    height: 13rem;
    /* border-radius: 10px; */
}
.blog_bottom_img2{
  width: 100%;
  height: 13rem;
 border-radius: 18px 18px 0 0; 
}

.blog_video_box h1{
  font-size: 1.1rem;
  color: #144D79;
  padding: 1rem .5rem 0rem 1rem;
  line-height: 1.5;
  text-align: left;
}
.blog_video_box p{
    font-size: .8rem;
    color: #707070;
    padding:0 1rem 0 1rem;
    text-align: left;
  }
.read_more{
  font-weight: bold;
    background-color: none;
    border: none;
    color: #ED1A3B;
    text-transform: uppercase;
    opacity: 1;
padding:1rem 0 0 1rem;
font-size: .8rem;
}
@media screen and (max-width:800px){
  .blog_top_section {
    height: 15rem;
    width: 90%;
  }
  .blog_top_heading h1 {
    color: #2C2024;
    text-align: center;
    padding: 4rem 0 0 1rem;
    opacity: 1;
  }
   /* topics section css here */

   .second_top_blog{
    margin-top: 0rem;
  }
  .all_blog_latest{
    margin-top: 1rem;
  }
.all_blog_latest h1{
  font-size: 1.2rem;
  color: #144D79;
  text-shadow: 0px 3px 6px #00000029;
  padding-top: 2rem;

}
.all_blog_latest p{
  font-size: .8rem;
  color: #144D79;
 padding: 0rem 0 .5rem 0;

}
.blog_img_techno{
  margin-top: 2rem;
  width:100%;
  height: 60%;
}



/* 
.topics_heading h1{
    margin: 2rem 0 0rem 0;
    font-size: 1.1rem;
}
.line2{
  top: 35%;
  left: 50%;
  width: 60%;
}
.topics_heading h3{
  padding-top: 3rem;
  text-align: center;
font-size: 1rem;
} */
/* blogvideo section css */
.welcome_blog h1{
  text-align: center;
  font-size: 1.5rem;
  color: #144D79;
  padding-top: 1rem;
}
.welcome_blog p{
  text-align: center;
  font-size: .8rem; 
}
.blog_video_section{
  margin-top: 2rem;
}
.blog_video_box{
  width:100%;
  height: 21rem;
}
.blog_video_box{
  width: 100%;
  height: 26rem;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 24px #0000003D;
border-radius: 18px;
opacity: 1; 
}
.blog_bottom_img {
  width: 100%;
  height: 12rem;
  /* border-radius: 10px; */
}

.blog_video_box h1{
  font-size: 1rem;
  padding: 1rem .5rem 0rem 1rem;
  line-height: 1.5;
  text-align: left;
}
.blog_video_box p{
    font-size: .9rem;
    color: #707070;
    padding:0 1rem 0 1rem;
    text-align: left;
  }
.read_more{
  font-weight: bold;
    background-color: none;
    border: none;
    color: #ED1A3B;
    text-transform: uppercase;
    opacity: 1;
padding:1rem 0 0 1rem;
font-size: .9rem;
margin-top: 1rem !important;
}
}