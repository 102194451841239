@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap'); */

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1,h2{
  font-family: 'Roboto', sans-serif !important;
  font-family: var(--Roboto) !important;
}
h1{
  font-size: 2rem;

}
h2{
  font-size: 1.5rem;
  
}
p{
  color: #696969;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif !important;
  font-family: var(--open) !important;
}
:root {
  --Roboto: 'Roboto', sans-serif;
  --open: 'Open Sans', sans-serif;
  --primary-color: #cc2020;
  --ambient-color: #f8694a;
  --accent-color: #30323a;
  --cool-color: #156064;
  font-size: 16px;
 
  --m-1:5rem;
  --m-2:8rem;
}
@media screen and (max-width:800px){
  h1{
    font-size: 1.4rem;
  
  }
  h2{
    font-size: 1.1rem;
    
  }
  p{
    color: #696969;
    font-size: .9rem;
    font-family: 'Open Sans', sans-serif !important;
    font-family: var(--open) !important;
  }
}
.main_container{
    overflow-x: hidden;
}
.home_header_section,
.home_services_section {

    background: #FFFFFF00 0% 0% no-repeat padding-box;
}
.home_digital{
    position: relative;
    margin-top:8rem;  
}
.home_digital h1{
    color: #2C2024;

}
.home_digital h2{
    letter-spacing: 13.44px;
color: #FCA70C;
opacity: 1;   
}
.home_digital p{
    color: #696969;
    padding: 2rem 0 2rem 0;
}
.message_block2{
   position: absolute;
   top: -5%;
   left:68%;
   transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
}

.btn_explore_more{
    font-size: .8rem;
    padding: 10px 25px;
    background: #147944 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 50px #0000001A;
border-radius: 100px;
color: #fff;
outline: none !important;
border: none;
opacity: 1;
}
.header_top_img{
    width: 100%;
    height: 80%;
}

/* home services css start here */
.circle111{
    margin: 2rem 0 0 1rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background: #FCEBAE 0% 0% no-repeat padding-box;
    display: inline-block;
    
}
.circle2{
    margin: 2rem 0 0 1rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background: #AAE5C6 0% 0% no-repeat padding-box;
    display: inline-block;
    
}
.circle3{
    margin: 2rem 0 0 1rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background: #E4BCFA 0% 0% no-repeat padding-box;
    display: inline-block;
    
}
.circle_img{
    width: 2rem;
    height: 2rem;
    margin: 1rem;
   
}
.background_shap{
    background-image: url(/static/media/shapeflow1.ea6045e5.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.development_boxes1{
    margin-top: 8rem;
    width: 12rem;
    height: 50%;
   background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 35px #14794415;
border-radius: 5px;
opacity: 0.8; 
} 
.development_boxes{
    width: 12rem;
    height: 50%;
   background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 35px #14794415;
border-radius: 5px;
opacity: 0.8; 
}
.development_boxes1 h3,
.development_boxes h3{
font-size: 1rem;
color: #000000;
padding: 1rem;
} 
.development_boxes1 p,
.development_boxes p{
  padding-left: 1rem;
  font-size: .8rem;

  } 
  .home_heading_services {
      margin-top: var(--m-2);
  }
  .home_heading_services h4{
    color: #147944;
    font-size: 1rem;
    padding:1rem 0 1rem 0;

  }
  .home_heading_services h3{
    color: #000000;
    font-size: 1.5rem;

  }
  .home_heading_services p{
 padding: 1rem 0 1rem 0;
  }
  .btn_see{
    font-size: .8rem;
    padding: 10px 25px;
    box-shadow: 10px 10px 50px #0000001A;
    border: 1px solid #147944;
    border-radius: 100px;
    color: #147944;
    opacity: 1; 
    font-weight: bold;
    text-decoration: none !important;
    margin-top: 1rem;
  }
  .btn_see:hover{
 background: #147944;
 color: #fff;
 border: 1px solid #147944;
 transition: .4s ease-in;
  }
  .arrow_grren{
    color: #147944;
    margin-left: 1rem;
  }
  .arrow_grren:hover{
    color: #fff;
  }
 /* products css start here */
 .home_products_section{
    background: transparent linear-gradient(90deg, #FFFFFFB8 0%, #FBF8F800 100%) 0% 0% no-repeat padding-box !important;
    opacity: 1;
 }
 .img1{
    
     border-radius: 10px;
    border: 5px solid #F092A8;
     position: relative;
     height:24rem;
     /* width: 25rem; */
     background-image: url(/static/media/1.29408eb3.png);
     background-size: 100% 100%;
     background-repeat: no-repeat;
     margin: 2rem 2rem 0 2rem;
 }
 .img2{
   
    border-radius: 10px;
   border: 5px solid #E4BCFA;
    position: relative;
    height:24rem;
    /* width: 25rem; */
    background-image: url(/static/media/4.ca9139ee.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 2rem 2rem 0 2rem;
}
.img3{
   
    border-radius: 10px;
   border: 5px solid #8BB9EC;
    position: relative;
    height:24rem;
    /* width: 25rem; */
    background-image: url(/static/media/3.7228d65c.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 2rem 2rem 0 2rem;
}
.img4{
  
    border-radius: 10px;
   border: 5px solid #AAE5C6;
    position: relative;
    height:24rem;
    /* width: 25rem; */
    background-image: url(/static/media/2.1472659a.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 2rem 2rem 0 2rem;
}
 .imgboxes{
     position: absolute;
     top: 80%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 90%;
     height: 8rem;
     /* margin:8rem .5rem 0 .5rem; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 35px #14794415;
    border-radius: 5px;
    opacity: 0.8;
 }
 
 .imgboxes h5{
    color: #000000;
    font-size: 1.2rem;
    padding: .5rem;
}
.imgboxes p{
    color: #707070;
    font-size: .8rem;
    padding:0 0 0 .5rem;
}
.btn_read_product{
    margin-left: 1rem;
    color: #ED1A3B;
    text-decoration: none;
    font-size: .8rem;
    background: none;
    border: none;
}
.btn_read_product:hover{
   font-size: .9rem;
   transition: .2s ease-in;
}
 .products_headings_left{
     margin-top: 10rem;
 }
 .products_headings_left h4{
    color: #147944;
    font-size: 1rem;
    padding:1rem 0 1rem 0;

  }
  .products_headings_left h3{
    color: #000000;
    font-size: 1.5rem;

  }
  .products_headings_left p{
 padding: 1rem 0 1rem 0;
  }
.product_img_right{
    width: 100%;
    height: 15rem;
}
/* digital marketing css here */
.home_digital_marketing{
    background: #FFFFFF00 0% 0% no-repeat padding-box;
}
.home_digital_marketing_heading {
    margin-top: var(--m-2);
}
.home_digital_marketing_heading h4{
  color: #147944;
  font-size: 1rem;
  padding:1rem 0 1rem 0;

}
.home_digital_marketing_heading h3{
  color: #000000;
  font-size: 1.5rem;

}
.home_digital_marketing_heading p{
padding: 1rem 0 1rem 0;
}
.about_home_img{
    margin-top: 5rem;
    width: 100%;
    height: 80%;
}

/* our work section  */ 
.home_our_work_section{
    background: transparent linear-gradient(271deg, #FEFFFD 0%, #EDFFF7 100%) 0% 0% no-repeat padding-box;
opacity: 1;
}   
.workfirst{
    width: 8rem;
    height: 12rem;
    margin: 4rem 0 3rem 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 35px #14794415;
border-radius: 5px;
opacity: 0.8;
}
.workfirst p{
    text-align: center;
  
}
.worksecond{
    width: 8rem;
    height: 12rem;
    margin: 8rem 0 3rem 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 35px #14794415;
border-radius: 5px;
opacity: 0.8;
}
.worksecond p{
    text-align: center;
  
}
.number_circle{
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    float: right;
    background: #2680EB 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: inline-block;
    font-size: .6rem;
    margin: 1rem;
}
.number_circle p{
    color: #fff;
   font-size: .6rem;
   text-align: center;
   padding-top: .3rem;
}
.img_circle{
    margin: 0rem 0 1rem 2rem;
    width: 4rem;
    height: 4rem;
    background: #FEF8E2 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: inline-block;

}
.work_imgs_circle{
    width: 2rem;
    height: 2rem;
    margin: 1rem;
}
.home_work_flow {
    margin-top: 10rem;
  
}
.home_work_flow h4{
  color: #147944;
  font-size: 1rem;
  padding:1rem 0 1rem 1.5rem;

}
.home_work_flow h3{
  color: #000000;
  font-size: 1.5rem;
  padding-left: 1.5rem;

}
.home_work_flow p{
padding: 1rem 0 1rem 1.5rem;
}
/* blog video css start here */

.home_blog_section{
    background: #FFE59E 0% 0% no-repeat padding-box;
opacity: 0.78;
}
.blog_top_heading{
    margin-top: 3rem;

}
.blog_top_heading h4{
    color: #147944;
    font-size: 1rem;
    padding:1rem 0 1rem 0;
  
  }
  .blog_top_heading h3{
    color: #000000;
    font-size: 1.5rem;
   
  
  }
  .blogbox{
    width: 15rem;
    height: 20rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000003D;
  border-radius: 18px;
  opacity: 1; 
}
.blogbox_img1{
    width: 15rem;
    height: 9rem;
}
.blogbox_img2{
    width: 15rem;
    height: 9rem;
    border-radius: 18px 18px 0 0;
}
.blogbox h1{
  font-size: .9rem;
  color: #424242;
  padding: 1rem 0 .5rem 1rem;
  text-align: left;
}
.blogbox p{
    font-size: .8rem;
    color: #999999;
    padding-left:1rem;
    text-align: left;
  }
.swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
.read_more{
    background-color: none;
    border: none;
    color: #2C98E3;
text-transform: uppercase;
opacity: 1;
padding-left: .5rem;
font-size: .8rem;
}
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 320px;
    /* height: 300px; */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */

  }
  .swiper_my {
    background-position: center;
    background-size: cover;
    width: 440px;
    /* height: 300px; */
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */

  }
  /* blog video css start here */
.blog_top_heading{
    margin-top: 2rem;

}
.blog_top_heading h4{
    color: #147944;
    font-size: 1rem;
    padding:0rem 0 1rem 0;
  
  }
  .blog_top_heading h3{
    color: #000000;
    font-size: 1.3rem;
    padding-bottom: 1rem;
   
  
  }
 /* testimonails section start here */
  .home_blog_heading {
    margin-top: 2rem;
  
}
.home_blog_heading h4{
  color: #147944;
  font-size: 1rem;
  padding:1rem 0 1rem 1rem;

}
.home_blog_heading h3{
  color: #000000;
  font-size: 1.5rem;
  padding-left: 1rem;

}
.home_blog_heading p{
padding: 1rem 0 1rem 1rem;
font-size: .9rem;
line-height: 2;
}
.blog_home_img{
    margin-top: 8rem;
    width: 100%;
    height: 80%;
}

/* call request back css here */
.home_callrequest_section{
    position: relative;
    background: transparent linear-gradient(180deg, #FEFFFD 0%, #EDFFF7 100%) 0% 0% no-repeat padding-box;
opacity: 1;
}
.home_call_request {
    margin-top: var(--m-1);
}
.home_call_request h4{
    position: relative;
    color: #147944;
    font-size: 1rem;
    padding:1rem 0 1rem 0;
    z-index: 999;
  
  }
  .home_call_request h3{
      position: relative;
    color: #000000;
    font-size: 1.5rem;
    z-index: 999;
    
  
  }
  .email_form{
      position: relative;
      z-index: 999;
  }
  .email_form input{
      
      width: 80%;
      padding: 10px 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 40px 45px #0000001A;
    border-radius: 100px;
    opacity: 1;
    outline: none;
    border: none;
    margin: 4rem 0 2rem 0;
    
  }
  .email_class::-webkit-input-placeholder{
      color: #333!important;
  }
  .email_class:-ms-input-placeholder{
      color: #333!important;
  }
  .email_class::placeholder{
      color: #333!important;
  }
  .send_circle{
      position: absolute;
      top: 62%;
      left:70%;
      transform: translate(-50%, -50%);
      width: 2rem;
      height: 2rem;
      display: inline-block;
      float: right;
      background: #147944;
      border-radius: 50%;
      outline: none;
      border: none;
  }
  .send_icon{
      width: 1rem;
      height: 1rem;
      margin-bottom: .2rem;
      text-align: center;
      color: #fff;

  }
  .calback_img_div{
   margin-bottom: 8rem;
  }
  .callback_img{
   
      width: 80%;
      height: 60%;
  }
  .background_shapes ul li{
      text-decoration: none;
  }
 .all_circle{
    text-decoration: none;
     position: absolute;
     top: 30%;
     left: 10%;
     transform: translate(-50%, -50%);
     width: 8rem;
     height: 8rem;
     border-radius: 50%;
     z-index: 1;
    background: transparent linear-gradient(36deg, #DEFFF0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
 }
 @media screen and (max-width:800px){
    .main_container{
        overflow-x: hidden;
    }
    .home_digital{
        margin-top:4rem;  
    }
    .home_digital h1{
        color: #2C2024;
        font-size: 1.8rem;
    
    }
    .home_digital h2{
        font-size: 1.2rem;
        letter-spacing: 5.44px;
    color: #FCA70C;
    opacity: 1;   
    }
    .home_digital p{
        color: #696969;
        padding: 1rem 0 1rem 0;
    }
    .message_block2{
        top: -5%;
        left:74%;
         width: 3rem;
         height: 3rem;
     }
     .header_top_img{
        width: 100%;
        height: 70%;
    }

    /* home services css start here */
.circle111{
    margin: 1rem 0 0 1rem;
    width: 3rem;
    height: 3rem; 
}
.circle2{
    margin: 1rem 0 0 1rem;
    width: 3rem;
    height: 3rem; 
    
}
.circle3{
    margin: 1rem 0 0 1rem;
    width: 3rem;
    height: 3rem; 
    
}
.circle_img{
    width: 1.5rem;
    height: 1.5rem;
    margin: .8rem;
   
}

.development_boxes1{
    margin-top: 8rem;
    width: 9rem;
    height: 14rem;
  
} 
.development_boxes{
    width: 9rem;
    height: 14rem;
    margin-bottom: 1rem;
  
}
.development_boxes1 h3,
.development_boxes h3{
font-size: .9rem;
padding: 1rem 0 .5rem 1rem;
} 
.development_boxes1 p,
.development_boxes p{
  padding-left: 1rem;
  font-size: .7rem;

  } 
  .home_heading_services {
    margin-top: 2rem;
} 
/* products css start here */
.products_headings_left{
    margin-top: 3rem;
}
.products_headings_left p{
    padding: 1rem .5rem 1rem 0;
     }

     .img1{
        height:24rem;
      
        margin: 2rem 1.5rem 0 1.5rem;
    }
    .img2{
        height:24rem;
       
        margin: 2rem 1.5rem 0 1.5rem;
   }
   .img3{
      
    height:24rem;

    margin: 2rem 1.5rem 0 1.5rem;
   }
   .img4{
       height:24rem;
       margin: 2rem 1.5rem 0 1.5rem;
   }

   .swiper_my {
    background-position: center;
    background-size: cover;
    width: 360px;
  }
/* digital marketing css here */
.home_digital_marketing_heading {
    margin-top: 2rem;
}
.home_digital_marketing_heading p{
    padding: 1rem .5rem 1rem 0;
    }
    .about_home_img{
        margin-top: 3rem;
        height: 60%;
    }

    /* our work section  */   
.workfirst{
    width: 8rem;
    height: 12rem;
    margin: 4rem 0 2rem 0;
  
}
.worksecond{
    width: 8rem;
    height: 12rem;
    margin: 2rem 0 2rem 0;
    
}
.img_circle{
    margin: 0rem 0 1rem 1.5rem;
    width: 5rem;
    height: 5rem;
    background: #FEF8E2 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: inline-block;

}
.work_imgs_circle{
    width: 2rem;
    height: 2rem;
    margin: 1.5rem;
}
.home_work_flow {
    margin-top: 2rem;
  
}
 /* testimonails section start here */
 .home_blog_heading {
    margin-top: 0rem;
  
}
.blog_home_img{
    margin-top: 3rem;
    width: 100%;
    height:15rem;
    margin-left: 4rem;
}
/* call request back css here */

.home_call_request {
    margin-top:2rem;
}
.email_form input{
    width: 90%;
  margin: 2rem 0 2rem 0;
  
}
.send_circle{
    top: 51%;
    left:80%;
   
}
.calback_img_div{
    margin-bottom: 4rem;
   }
 }
.about_top_section {
  position: relative;
  background-image: url(/static/media/p6.d43bc95c.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 30rem;
  width: 100%;
}
.about_top_heading {
  position: relative;
}
.about_top_heading h1 {
  color: #fff;
  text-align: center;
  padding: 8rem 0 0 1rem;
  opacity: 1;
}
.navlink_homeandcontact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.home_and_about p {
  display: inline-block;
  text-align: center;
  color: #fff !important;
}
.home_and_about p:hover {
  color: #fff !important;
  font-size: 1.1rem;
}
.digial_future {
  margin-top: 5rem;
}
.digial_future h4 {
  color: #c2c2c2;
  font-size: 1rem;
  padding: 1rem 0 0 0;
}
.digial_future h3 {
  color: #ffffff;
  font-size: 1.8rem;
}
.digial_future p {
  color: #ffffff;
  font-size: 0.8rem;
  padding-top: 2rem;
}

/* seconde part csss */

.stort_heading,
.vision_heading {
  position: relative;
  margin-top: 8rem;
}
.stort_heading h2 {
  color: #144d79;
  border-bottom: 4px solid #ee6060;
  width: 75%;
}
.stort_heading p,
.vision_heading p {
  color: #707070;
  padding: 1rem 0 2rem 0;
}
.btn_explore_more {
  font-size: 0.8rem;
  padding: 10px 25px;
  background: #144d79 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 50px #0000001a;
  border-radius: 100px;
  color: #fff;
  outline: none !important;
  border: none;
  text-decoration: none !important;
  font-weight: bold;
  opacity: 1;
}
.btn_explore_more:hover {
 
  color: #144d79;
  background: #fff;
  transition: .4s ease-in;
  border: 1px solid #144d79;

}

.story_video iframe{
  width:100%;
  height:20rem;
  margin-top: 5rem;
}
/* third section css */
.vision_heading h2 {
  color: #144d79;
  border-bottom: 4px solid #ee6060;
  width: 33%;
}
.header_top_img1 {
  margin-top: 5rem;
  width: 100%;
  height: 80%;
}
/* values section css */
.value_about_heading h2 {
  padding-top: 5rem;
    color: #144d79;
    border-bottom: 4px solid #ee6060;
    width: 28%;
  }
  .value_about_heading p {
    color: #707070;
    padding: 1rem 0 2rem 0;
  }
  /* .respect_box1{
    display: flex;
    justify-content:space-around;
    align-items: center;
    flex-direction: row;
    margin-top: 1rem;
    width:80%;
   max-height:60%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 15px #00000029;
border-radius: 34px;
opacity: 1;
}
.repect_onecol{
  display: flex;
  align-items: center;
 
  flex-direction: column;
}
.first_img img{
    display: inline-block;
    width: 3rem;
    height: 3rem;
   
}
.first_img p{
    display: inline-block;
    font-size: 1rem;
    color: #333;
    padding-left: .5rem;
}
.btn_round{
    margin-top: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50% !important;
   color:#fff;
   border: 2px solid #fff !important;
    background: #ec615b !important;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
}
.card_heading p{
    color: #333;
    font-size: 1.2rem;
}
.myperafirst{
    color: #333;
    font-size: 1.2rem;
} */

/* ----------------------------new values css start here-------------------------------- */
/* .my_new_values{
margin: 5rem 0 10rem 0;
  
} */
/* .new_values_container{
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
.accordion-item{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 34px;
  opacity: 1;
  border-radius: .4rem;
  margin-bottom: 1rem;
  padding: 1rem;
  

}
.accordion-link{
  font-size: 1.6rem;
  color: rgba(255,255,255,.5);
  text-decoration: none;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.accordion-link .ion-md-add, .ion-md-remove{
  color: #333;
  
}
.accordion-link .ion-md-remove{
  display: none;
}
.answer{
  max-height: 0;
  overflow: hidden;
  position: relative;
 
  background-color: #fff;
  transition:max-height 650ms;
}
.answer::before{
content: "";
  position: absolute;
  width: .6rem;
  height: 90%;
  background-color: #ec615b;
  top: 50%;
  left: 0;
  transform: translate(-50%,-50%);
 
}
.answer p{
  padding: 1rem;
  font-size: 1rem;
  color: #333;
}
.accordion-item:target .answer{
  max-height: 20%;

}
.accordion-item:target .accordion-link .ion-md-add {
  display: none;
}
.accordion-item:target .accordion-link .ion-md-remove {
  display: block;
}
.img_values_all{
  width: 3rem;
  height: 3rem;
}
.ion-md-add {
  display: inline-block;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #ec615b;
  color: #fff;
  
}
 */

 /* 3rd effect css */
.container{
  width: 100%;
  max-width: 480px;
  margin: auto;
}
.accordion{
  width: 100%;
  max-width: 540px;
  margin: 15px auto;
  color: #333;
}
.accordionHeading{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius:34px 34px 0 0;
  padding: 14px 0;
}
.accordionHeading .container{
display: flex;
align-items: center;
justify-content: space-between;
}
.accordionHeading p{
  letter-spacing: 1.2px;
  font-weight: 600;
}
.accordionHeading span{
  cursor: pointer;
}
.accordionContent{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius:0 0 34px 34px;
  height: 0;
  opacity: 0;
  display: flex;
  overflow: hidden;
  
  transition: all 600ms ease-in-out;
}
.accordionContent.show{

  height: 180px;
  opacity: 1;
 
}
.accordionContent p{
  padding-top: .5rem;
  font-size: 15px;
  line-height: 1.6;
}
.animated{
  width: 3rem;
  height: 3rem;
  margin:.2rem 2rem .2rem 1rem;
}
.icon_add_bg{
  width: 3rem;
  height: 3rem;
  display: inline-block;
  border-radius: 50%;
  background-color: #ec615b;
}
.icon_add{
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  margin: .8rem;
}
.icon_remove{
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  margin: .8rem;
}
/* what we get section */
.we_get_section{
    margin-top: 3rem;
    background: #F9FFDC 0% 0% no-repeat padding-box;
}
.get_heading h2 {
  padding-top: 2rem;
    color: #144d79;
    border-bottom: 4px solid #ee6060;
    width: 28%;
  }
  .get_heading p {
    color: #707070;
    padding: 1rem 0 2rem 0;
  }
  .benefit_heading h3{
    color: #2C2024;
    font-size: 1.5rem;
    text-align: center;
  }
  .benefit_heading p{
    color: #707070;
    padding: .5rem 0;

 
  }
  .gift_and_heart_img{
    width: 3rem;
    height: 3rem;
  }
  /* our people css start here */
  .our_people_section{
    margin-top: 3rem;
}
.our_people_heading h2 {
    color: #144d79;
    border-bottom: 4px solid #ee6060;
    width: 28%;
  }
  .our_people_heading p {
    color: #707070;
    padding: 1rem 0 2rem 0;
  }
  .people_box1{
    position: relative;
      height: 16rem;
      width: 100%;
      background: transparent linear-gradient(180deg, #8182EE 0%, #2d516d 100%) 0% 0% no-repeat padding-box;
      box-shadow: 6px 6px 20px #00000029;
      border-radius: 55px;
      opacity: 0.85; 
      /* background-image: url('../../images/cover.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat; */
    border-radius: 6px;
    opacity: 1;
    margin-bottom: 1.5rem;
  }
  .circle_team_img{
    position: absolute;
    display: inline-block;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }
  .circle_team_img2{
    background: #fff;
    position: absolute;
    display: inline-block;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }
  .people_box1 h3{
    padding-top: 12rem;
    font-size: 1.2rem;
    color: #fff;
    text-shadow: 2px 2px 2px #00000029;
    text-align: center;
 
}
.people_box1 h4{
  font-size: .8rem;
  color: #fff;
  text-align: center;

}
 


@media screen and (max-width:800px){
  .about_top_section {
    position: relative;
    background-image: url(/static/media/p6.d43bc95c.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 17rem;
    
  }
  .about_top_heading h1 {
    color: #fff;
    text-align: center;
    padding: 4rem 0 0 1rem;
    opacity: 1;
  }
  .digial_future {
    margin-top: 0rem !important;
  }
  .digial_future h4 {
    font-size: 1rem;
    padding: .5rem 0 0 0;
  }
  .digial_future h3 {
    font-size: 1.3rem !important;
  }
  .digial_future p {
    font-size: 0.8rem;
    padding-top: 0rem !important;
  }
/* seconde part csss */

.stort_heading,
.vision_heading {
  margin-top: 3rem;
  position: relative;
}
.stort_heading h2 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
 font-size: 1.5rem;
  width: 90%;
  text-align: center;
}
.stort_heading p,
.vision_heading p {
  padding: 3rem 0 1rem 0;
}
.header_top_img1 {
  margin-top: 0rem;
  width: 100%;
  height: 70%;
}
.story_video iframe{
  width:100%;
  height:17rem;
  margin-top: 2rem;
}
/* third section css */
.vision_heading {
  position: relative;
}
.vision_heading h2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 70%;
}
/* values section css */
.value_about_heading h2 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 80%;
padding-top: 4rem;
}
.value_about_heading p {
  color: #707070;
  padding: 4.5rem 0 2rem 0;
}

  /* respect section css */
.respect_box1{
  
    margin-top: 0rem !important;
    width:100%;
    max-height:10rem;
    margin-bottom: 1rem !important;
}
.first_img img{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-bottom: 0rem;
  padding-right: 0;
}

.card_heading p{

    color: #333;
    font-size: .8rem;
    padding-top: 0rem;
    
}
.myperafirst{
    color: #333;
    font-size: .8rem;
    padding-top: 0rem;
}
.btn_round{
  margin-top: 0rem;
  width: 3rem;
  height: 3rem;
  margin-bottom: 2rem;
 
}
.respect_margin{
  margin: 0;
}

/* what we get section */
.we_get_section{
  margin-top: 0;
}
.get_heading h2 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 80%;
}
.get_heading p {

  padding: 5rem 0 1rem 0;
}
.benefit_heading h3{
  text-align: left;
}
.benefit_heading p{
  padding: .5rem 0;
}
 /* our people css start here */
 .our_people_section{
  margin-top: 1rem;
}
.our_people_heading h2 {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 80%;
}
.our_people_heading p {
  padding: 3rem 0 1rem 0;
}
/* .people_box h3{
  padding-top: 9.5rem;
  

} */
.sape_about_pepople{
  width: 10rem;

}

.people_box1{
  position: relative;
    height: 16rem;
 
}
.circle_team_img{
  position: absolute;
  display: inline-block;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.people_box1 h3{
  padding-top: 10rem;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;

}
.people_box1 h4{
font-size: .8rem;
color: #fff;
text-align: center;

}

}
.contact_top_section{
    position: relative;
    background-image: url(/static/media/contacttop.30fa38f5.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height:28rem;
    width: 100%;

}
.contact_top_heading{
    position: relative;
   
}
.contact_top_heading h1{
    color: #2C2024;
    text-align: center;
    padding: 8rem 0 0 1rem;
opacity: 1;
}
.navlink_homeandcontact{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}
.home_and_contact p{
    display: inline-block;
    text-align: center;
    color: #2C2024;  
}
.home_and_contact p:hover{
    color: #144D79;
    font-size: 1.1rem;
}
/* second section css */
.get_touch_section{
    margin-top: 2rem;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.get_touch_heading {
    margin-top: 5rem;
}
.get_touch_heading h2{
    font-size: 2rem;
    color: #144D79;

}
.get_touch_heading h3{
    font-size: 1.2rem;
    color: #2C2024;

}
.get_touch_heading p{

    color: #707070;
    padding-right: .5rem;

}
.form_sectio{
    margin-top: 5rem;
    position: relative;
    margin-left: 5rem;
    width: 26rem;
    height: 26rem;
    border-radius: 18px;
    background: #FEF6F6 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 20px #00000029;
opacity: 0.65;
}
.form_sectio h4{
    font-size: 1.2rem;
    padding: 2rem 0 .5rem 5rem;
    color: #2C2024;
 
}
.form_sectio p{
    padding: .2rem 0 .5rem 5rem;
    color: #706B70;
 
}
.form_section_div{
    margin: 1rem 0 0 5rem;
}
.contact_box{
    position: absolute;
    top: 50%;
    left: -5%;
    transform: translate(-50%, -50%);
    background: #144D79 0% 0% no-repeat padding-box;
box-shadow: 6px 6px 20px #00000029;
border-radius: 25px;
opacity: 0.67;
width: 11rem;
height: 10rem;
}
.contact_box h1{
    padding: 1rem 0 0 0;
    text-align: center;
    font-size: 1.1rem;
    letter-spacing: 2.6px;
color: #FFFFFF;
opacity: 1;
  
}
.form-control {
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: 2rem .75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* color: #495057; */
    color: #fff !important;
    /* background-color: #fff; */
    background-clip: padding-box;
    border: none !important;
    outline: none !important;
    border-radius: 21px  !important;
    width: 19rem !important;
    background: #6E6978 0% 0% no-repeat padding-box !important;
border-radius: 21px;
opacity: 1;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
::-webkit-input-placeholder {
    color:#fff !important;
  padding: 1rem 1rem !important;
}
:-ms-input-placeholder {
    color:#fff !important;
  padding: 1rem 1rem !important;
}
::placeholder {
    color:#fff !important;
  padding: 1rem 1rem !important;
}
.btn_submit2{
    outline: none !important;
    border: none !important;
    padding: 10px 25px !important;
    font-size: .8rem !important;
    color: #fff;
   border-radius:18px !important;
   box-shadow: 6px 6px 20px #00000029 !important;
   background: #144D79 0% 0% no-repeat padding-box !important;
}
.men_icon{
    position: absolute;
    top: 93%;
    left: 17%;
   
    transform: translate(-50%, -50%);
}
.men_img_bottom{
    position: relative;
    z-index:999;
    width: 3.5rem;
    height: 3.5rem;
}
.form_input_man{
   z-index: 1;
    position: absolute;
    top: 33.5%;
    left: 80%;
    transform: translate(-50%, -50%);
}
.user_img_bottom{
    color:#fff !important;
    width: 1rem;
    height: 1rem;
}
.email_icon{
   
     position: absolute;
     top: 46.5%;
     left: 80%;
     transform: translate(-50%, -50%);
 }
 .email_img_bottom{
    
     width: 1rem;
     height: 1rem;
 }
 input:-internal-autofill-selected {
    -webkit-appearance: menulist-button;
       -moz-appearance: menulist-button;
            appearance: menulist-button;
    /* background-color: rgb(232, 240, 254) !important; */
    background-color: #6E6978 0% 0% no-repeat padding-box !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
}
.my_lists{
     font-size: .8rem;
     text-align: center;
     color: #fff;
     text-decoration: none !important;
     padding:0 0 .4rem 0;
 }
 .my_lists a{
    font-size: .8rem;
    text-align: center;
    color: #fff;
    text-decoration: none !important;
    padding:0 0 .4rem 0;
}
.my_lists a:hover{
 
    color: #144D79;
  
}

/* map section css */
.map_section_heading{
    margin-top: 5rem;
}
.map_section_heading h2{
    font-size: 2rem;
    color: #144D79;

}
.map_section_heading h3{
    font-size: 1.2rem;
    color: #2C2024;

}
.map_section_heading iframe{
    width: 100%; 
    height: 25rem; 
    margin-top:2rem;
}
@media screen and (max-width:800px){
    .contact_top_section{
        height:9rem;
       
    }
    .contact_top_heading h1{
        padding: 3rem 0 0 1rem;

    }
    /* second section css */
.get_touch_section{
    margin-top: 1rem;
  
}
.get_touch_heading {
    margin-top: 2rem;
}
.get_touch_heading h2{
    font-size: 2rem;
}
.form_sectio{
    margin-top: 10rem;
    position: relative;
    margin-left: 0;
    width: 100%;
    height: 28rem;
  
opacity: 0.95;
}
.form_sectio h4{
  
    padding: 2rem 0 .5rem 2rem;
  
 
}
.form_sectio p{
    padding: .2rem 0 .5rem 2rem;
    color: #706B70;
 
}
.form_section_div{
    margin: 1rem 0 0 2rem;
}

.contact_box{
    top: -13%;
    left: 50%;
opacity: 0.67;
width: 14rem;
height: 10rem;
}
.form-control {
  width: 18rem !important;
 
}
.form_input_man{
     top: 31.5%;
     left: 79%;
    
 }
 .email_icon{
    top: 46%;
    left: 79%;
}
.men_icon{
    top: 94%;
    left: 5%;
}
.men_img_bottom{
    position: relative;
    z-index:999;
    width: 3rem;
    height: 3rem;
}
#form_1222{
   width: 95% !important;
    padding:1.5rem 0 1.5rem 0 !important;
}
#exampleFormControlInput1222{
    width: 95% !important;
    padding:1.5rem 0 1.5rem 0 !important;
}
#exampleFormControlTextarea123232{
    width: 95% !important;
    padding:1.5rem 0 1.5rem 0 !important;
}
/* map section css */
.map_section_heading{
    margin-top: 3rem;
}
}
.blog_top_section {
    position: relative;
    background-image: url(/static/media/Blog.c6c7cb99.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 25rem;
    width: 100%;
  }
  .blog_top_heading {
    position: relative;
  }
  .blog_top_heading h1 {
    color: #2C2024;
    text-align: center;
    padding: 8rem 0 0 1rem;
    opacity: 1;
  }
  .navlink_homeandcontact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .home_and_blog p {
    display: inline-block;
    text-align: center;
    color: #2C2024;
  }
  .home_and_blog p:hover {
    color: #2C2024;
    font-size: 1.1rem;
  }
  
  /* topics section css here */
  .second_top_blog{
    margin-top: 2rem;
  }
  .all_blog_latest{
    margin-top: 2rem;
  }
.all_blog_latest h1{
  font-size: 1.5rem;
  color: #144D79;
  text-shadow: 0px 3px 6px #00000029;
  padding-top: 3rem;

}
.all_blog_latest p{
  font-size: 1rem;
  color: #144D79;
 padding: 0rem 0 .5rem 0;

}
.explore_more_blog{
  outline: none !important;
  border: none;
  padding: 10px 25px ;
  font-size: .8rem ;
  color: #fff;
 border-radius:18px;
 box-shadow: 6px 6px 20px #00000029 ;
 background: #144D79 0% 0% no-repeat padding-box ;
 font-weight: bold;

}
.explore_more_blog:hover{
 background-color: #fff;
 color: #144D79;
 border: 1px solid #144D79;
 text-decoration: none !important;
 transition: .3s ease-in;
 
}
.blog_img_techno{
  margin-top: 5rem;
  width:100%;
  height: 60%;
}
 
/* blogvideo section css */

.welcome_blog h1{
  text-align: center;
  font-size: 2rem;
  color: #144D79;
  padding-top: 1rem;
}
.welcome_blog p{
  text-align: center;
  font-size: 1rem; 
}
.blog_video_section{
    margin-top: 2rem;
}
.blog_video_box{
    width: 100%;
    height: 25rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 24px #0000003D;
  border-radius: 18px;
  opacity: 1; 
}
.blog_bottom_img {
    width: 100%;
    height: 13rem;
    /* border-radius: 10px; */
}
.blog_bottom_img2{
  width: 100%;
  height: 13rem;
 border-radius: 18px 18px 0 0; 
}

.blog_video_box h1{
  font-size: 1.1rem;
  color: #144D79;
  padding: 1rem .5rem 0rem 1rem;
  line-height: 1.5;
  text-align: left;
}
.blog_video_box p{
    font-size: .8rem;
    color: #707070;
    padding:0 1rem 0 1rem;
    text-align: left;
  }
.read_more{
  font-weight: bold;
    background-color: none;
    border: none;
    color: #ED1A3B;
    text-transform: uppercase;
    opacity: 1;
padding:1rem 0 0 1rem;
font-size: .8rem;
}
@media screen and (max-width:800px){
  .blog_top_section {
    height: 15rem;
    width: 90%;
  }
  .blog_top_heading h1 {
    color: #2C2024;
    text-align: center;
    padding: 4rem 0 0 1rem;
    opacity: 1;
  }
   /* topics section css here */

   .second_top_blog{
    margin-top: 0rem;
  }
  .all_blog_latest{
    margin-top: 1rem;
  }
.all_blog_latest h1{
  font-size: 1.2rem;
  color: #144D79;
  text-shadow: 0px 3px 6px #00000029;
  padding-top: 2rem;

}
.all_blog_latest p{
  font-size: .8rem;
  color: #144D79;
 padding: 0rem 0 .5rem 0;

}
.blog_img_techno{
  margin-top: 2rem;
  width:100%;
  height: 60%;
}



/* 
.topics_heading h1{
    margin: 2rem 0 0rem 0;
    font-size: 1.1rem;
}
.line2{
  top: 35%;
  left: 50%;
  width: 60%;
}
.topics_heading h3{
  padding-top: 3rem;
  text-align: center;
font-size: 1rem;
} */
/* blogvideo section css */
.welcome_blog h1{
  text-align: center;
  font-size: 1.5rem;
  color: #144D79;
  padding-top: 1rem;
}
.welcome_blog p{
  text-align: center;
  font-size: .8rem; 
}
.blog_video_section{
  margin-top: 2rem;
}
.blog_video_box{
  width:100%;
  height: 21rem;
}
.blog_video_box{
  width: 100%;
  height: 26rem;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 24px #0000003D;
border-radius: 18px;
opacity: 1; 
}
.blog_bottom_img {
  width: 100%;
  height: 12rem;
  /* border-radius: 10px; */
}

.blog_video_box h1{
  font-size: 1rem;
  padding: 1rem .5rem 0rem 1rem;
  line-height: 1.5;
  text-align: left;
}
.blog_video_box p{
    font-size: .9rem;
    color: #707070;
    padding:0 1rem 0 1rem;
    text-align: left;
  }
.read_more{
  font-weight: bold;
    background-color: none;
    border: none;
    color: #ED1A3B;
    text-transform: uppercase;
    opacity: 1;
padding:1rem 0 0 1rem;
font-size: .9rem;
margin-top: 1rem !important;
}
}
.career_top_section2 {
    position: relative;
    background-image: url(/static/media/careers2.c0026b02.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 32rem;
  
  }
  .career_top_heading2 {
    position: relative;
  }
  .career_top_heading2 h1 {
    color: #2C2024;
    text-shadow: 5px 3px 6px #00000029;
    opacity: 1;
    text-align: center;
    padding: 8rem 0 0 1rem;
    opacity: 1;
  }
  .navlink_homeandcontact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .home_and_career2 p {
    text-shadow: 5px 3px 6px #00000029;
    display: inline-block;
    text-align: center;
    color: #2C2024;
  }
  .home_and_career2 p:hover {
    text-shadow: 5px 3px 6px #00000029;
    color: #2C2024;
    font-size: 1.1rem;
  }
  .career_heading h5{
     
      text-align: center;
      padding-top: 3rem;
      font-size: 1rem;
      border-bottom: 4px solid #FF0000 !important;
  }

  .we_hiring {
    margin-top: 0rem;
  }
  .we_hiring h4 {
    color: #8182EE;
    font-size: 1rem;
    padding: 1rem 0 0 0;
  }
  .we_hiring h3 {
    color: #2d516d;
text-shadow: 0px 3px 6px #00000029;
opacity: 1;
    font-size: 1.8rem;
  }
 .btn_hover_effect{
   margin-top: 2rem;
  font-size: 0.8rem;
  padding: 10px 35px;
  background: #144d79 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 50px #0000001a;
  border-radius: 100px;
  color: #fff;
  outline: none !important;
  font-weight: bold !important;

  border: none;
  opacity: 1;
 
 }
 .btn_hover_effect:hover{
background: #fff;
color: #144d79;
border: 1px solid #144d79;
transition: .4s ease-in;

}
 /* form section css start */
 
 .form_section2{
  margin-top: 2rem;
  position: relative;
  margin-left: 5rem;
  width: 22rem;
  height: 26rem;
  border-radius: 18px;
  background: #FEF6F6 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 20px #00000029;
opacity: 0.65;
}
.form_section2 h4{
  font-size: 1.1rem;
  padding: 2rem 0 .5rem 5rem;
  color: #2C2024;

}
.form_section2 p{
  font-size: .8rem;
  padding: .2rem 0 .5rem 5rem;
  color: #706B70;

}
.form_section_div{
  margin: 1rem 0 0 5rem;
}
.contact_career_box{
  position: absolute;
  top: 50%;
  left: -3%;
  transform: translate(-50%, -50%);
  background: #144D79 0% 0% no-repeat padding-box;
box-shadow: 6px 6px 20px #00000029;
border-radius: 25px;
opacity: 0.67;
width: 9rem;
height: 10rem;
}
.contact_career_box h1{
  padding: 1rem 0 0 0;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 2.6px;
color: #FFFFFF;
opacity: 1;

}
.form-control {
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: 1rem .75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* color: #495057; */
  color: #fff !important;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: none !important;
  outline: none !important;
  border-radius: 21px  !important;
  width: 19rem !important;
  background: #6E6978 0% 0% no-repeat padding-box !important;
border-radius: 21px;
opacity: 1;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#form_2{
  width: 15rem !important;
}
#exampleFormControlInput11{
  width: 15rem !important;
}
#exampleFormControlTextarea12{
  width: 15rem !important;
}
::-webkit-input-placeholder {
  color:#fff !important;
padding: 1rem 1rem !important;
}
:-ms-input-placeholder {
  color:#fff !important;
padding: 1rem 1rem !important;
}
::placeholder {
  color:#fff !important;
padding: 1rem 1rem !important;
}
.btn_submit2{
  outline: none !important;
  border: none !important;
  padding: 10px 25px !important;
  font-size: .8rem !important;
  color: #fff;
 border-radius:18px !important;
 box-shadow: 6px 6px 20px #00000029 !important;
 background: #144D79 0% 0% no-repeat padding-box !important;
}
.men_icon2{
  position: absolute;
  top: 93%;
  left: 17%;
 
  transform: translate(-50%, -50%);
}
.men_img_bottom2{
  position: relative;
  z-index:999;
  width: 3.5rem;
  height: 3.5rem;
}
.form_input_man2{
 z-index: 1;
  position: absolute;
  top: 32.5%;
  left: 80%;
  transform: translate(-50%, -50%);
}
.user_img_bottom2{
  color:#fff !important;
  width: 1rem;
  height: 1rem;
}
.email_icon2{
 
   position: absolute;
   top: 45.5%;
   left: 80%;
   transform: translate(-50%, -50%);
}
.email_img_bottom2{
  
   width: 1rem;
   height: 1rem;
}
input:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
     -moz-appearance: menulist-button;
          appearance: menulist-button;
  /* background-color: rgb(232, 240, 254) !important; */
  background-color: #6E6978 0% 0% no-repeat padding-box !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}
.my_lists{
   font-size: .8rem;
   text-align: center;
   color: #fff;
   text-decoration: none !important;
   padding:0 0 .4rem 0;
}


 /* form section css end here */
 /* top section end here */
  
  /* who we are section */
  .whowe_blog_section{
      position: relative;
  }
  .who_we_top_img{
      position: relative;
    
      margin-top: 5rem;
      margin-bottom: 0;
      width: 90%;
      height: 20rem;
  }
  .whowe_headings {
      margin-top: 4rem;
  }
  .whowe_headings h2{
    border-bottom: 4px solid #EE6060;
    width: 60%;
      padding: 5rem 0  1rem 0;
font-size: 1.8rem;
color: #144D79;
  }
  .whowe_headings p{
    font-size: 1.1rem;
    color: #707070;
      }
      .all_circle2{
        text-decoration: none;
         position: absolute;
         top: 75%;
         left: 5%;
         transform: translate(-50%, -50%);
         width: 8rem;
         height: 8rem;
         border-radius: 50%;
      
         background: #D5BED145 0% 0% no-repeat padding-box;
         border: 1px solid #7070700D;
         opacity: 1;
     }
     .all_circle3{
        text-decoration: none;
         position: absolute;
         top: 20%;
         left: 87%;
         transform: translate(-50%, -50%);
         width: 8rem;
         height: 8rem;
         border-radius: 50%;
      
         background: #D5BED145 0% 0% no-repeat padding-box;
         border: 1px solid #7070700D;
         opacity: 1;
     }
     /* work section css start here */
.work_with_us h2{
  border-bottom: 4px solid #EE6060;
  width:25%;
    padding: 5rem 0  1rem 0;
font-size: 1.8rem;
color: #144D79;
}
/* work career second section */
.work2_section{
  margin-top: 4rem;
}
.dot_background{
  background-image: url(/static/media/grid.db18cfa6.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.all_careers_options{
  margin: 0rem 0 1rem 0rem;
  background-color: #144D79;
  width: 10rem;
  height: 6.5rem;
}
.all_careers_options h4{
  color: #FFF7F7;
  font-size: .9rem;
  text-align: center;
  padding: 2rem .4rem 0 .4rem;
}
.responsibilites_headings h3{
  padding: 1.5rem 0 .5rem 0;
  color: #2C2024;
  font-size: 1.4rem;
}
.responsibilites_headings p{

  color: #2C2024;
  font-size: .9rem;
line-height: 2;
}
.work_images{
  margin-top: 8rem;
  width:100%;
  height: 25rem;
}
.contact_to_hr{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  background: #207181 0% 0% no-repeat padding-box;
border: 1px solid #707070;
opacity: 0.4;
}
.hr_box a{
  text-decoration: none;
  font-size: 2rem;
  /* color: #144D79; */
  color: #fff;
}
    @media screen and (max-width:800px){
      .career_top_section2 {
      
        height: 18rem;
        width: 100%;
      
      }
      .career_top_heading2 h1 {
       
        padding: 1rem 0 0 1rem;
        
      }
      .we_hiring {
        margin-top: 0rem;
      }
      .we_hiring h4 {
        color: #8182EE;
        font-size: .9rem;
        padding: 1rem 0 0 0;
      }
      .we_hiring h3 {
        color: #2d516d;
    text-shadow: 0px 3px 6px #00000029;
    opacity: 1;
        font-size: .8rem;
      }
     .btn_hover_effect{
       margin-top: 1rem;
      font-size: 0.8rem;
      padding: 8px 35px;
      background: #144d79 0% 0% no-repeat padding-box;
      box-shadow: 10px 10px 50px #0000001a;
      border-radius: 100px;
      color: #fff;
      outline: none !important;
      border: none;
      opacity: 1;
     
     }

      /* form section css here */
      .form_section2{
        margin-top: 7rem;
        position: relative;
        margin-left: 0;
        width: 100%;
        height: 26rem;
    opacity: 0.95;
    }
    .form_section2 h4{
        padding: 2rem 0 .5rem 2rem;
    }
    .form_section2 p{
        padding: .2rem 0 .5rem 2rem;
        color: #706B70;
    }
    .form_section_div{
        margin: 1rem 0 0 1rem;
    }
    
    .contact_career_box{
        top: -13%;
        left: 50%;
    opacity: 0.67;
    width: 13rem;
    height: 9rem;
    }
    .form-control {
      width: 18rem !important;
    }
    .form_input_man2{
         top: 32%;
         left: 82%;
        
     }
     .email_icon2{
        top: 45%;
        left: 82%;
    }
    .men_icon2{
        top: 94%;
        left: 9%;
    }
    .men_img_bottom2{
        position: relative;
        z-index:999;
        width: 3rem;
        height: 3rem;
    }
    #form_2{
      width: 95% !important;
    }
    #exampleFormControlInput11{
      width: 95% !important;
    }
    #exampleFormControlTextarea12{
      width: 95% !important;
    }


  /* who we are section */

.who_we_top_img{
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 17rem;
}
.whowe_headings {
    margin-top: 1rem;
}
.whowe_headings h2{
    padding: 0rem 0  1rem 0;
font-size: 1.3rem;

}
.whowe_headings p{
  font-size: 1rem;
 
    }
    .work_with_us h2{
      border-bottom: 4px solid #EE6060;
      width:48%;
        padding: 1rem 0  0rem 0;
    font-size: 1.3rem;
    color: #144D79;
    }
    .work2_section{
      margin-top: 1rem;
    }
    .all_careers_options{
      margin: 0rem 0rem 0rem 0rem;
      width: 6rem;
      height: 6rem;
    }
    .all_careers_options h4{
      color: #FFF7F7;
      font-size: .9rem;
      text-align: center;
      padding: 2rem .4rem 0 .4rem;
    }
    .responsibilites_headings h3{
      padding: 1rem 0 .5rem 0;
      color: #2C2024;
      font-size: 1.2rem;
    }
    .responsibilites_headings p{
    
      color: #2C2024;
      font-size: .8rem;
    line-height: 1.5;
    }
    .work_images{
      margin-top: 0rem;
      width:100%;
      height: 20rem;
    }
    .contact_to_hr{
      height: 6rem;
    }
    .hr_box a{

      font-size: 1rem;
      
    }
    }
.product_top_section {
    position: relative;
    background-image: url(/static/media/product.e7791ded.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 25rem;
    width: 100%;
  }
  .product_top_heading {
    position: relative;
  }
  .product_top_heading h1 {
    color: #fff;
    text-align: center;
    padding: 8rem 0 0 1rem;
    opacity: 1;
  }
  .navlink_homeandcontact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .home_and_product p {
    display: inline-block;
    text-align: center;
    color: #fff;
  }
  .home_and_product p:hover {
    color: #fff;
    font-size: 1.1rem;
  }

  .blue-bg {
    background-color: #fff;
    color: #EE6060;
    height: 100%;
  }
  
  .circle {
    font-weight: bold;
    padding: 15px 20px;
    border-radius: 50%;
    background-color: #EE6060;
    color: #4D4545;
    max-height: 50px;
    z-index: 2;
  }
  
  .how-it-works.row {
    display: flex;
  }
  .how-it-works.row .col-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
  }
  .how-it-works.row .col-2::after {
    content: "";
    position: absolute;
    border-left: 3px solid #EE6060;
    z-index: 1;
  }
  .how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%;
  }
  .how-it-works.row .col-2.full::after {
    height: 100%;
    left: calc(50% - 3px);
  }
  .how-it-works.row .col-2.top::after {
    height: 50%;
    left: 50%;
    top: 0;
  }
  
  .timeline div {
    padding: 0;
    height: 40px;
  }
  .timeline hr {
    border-top: 3px solid #EE6060;
    margin: 0;
    top: 17px;
    position: relative;
  }
  .timeline .col-2 {
    display: flex;
    overflow: hidden;
  }
  .timeline .corner {
    border: 3px solid #EE6060;
    width: 100%;
    position: relative;
    border-radius: 15px;
  }
  .timeline .top-right {
    left: 50%;
    top: -50%;
  }
  .timeline .left-bottom {
    left: -50%;
    top: calc(50% - 3px);
  }
  .timeline .top-left {
    left: -50%;
    top: -50%;
  }
  .timeline .right-bottom {
    left: 48.5%;
    top: calc(50% - 3px);
  } 
  .indroducting_heading{
    padding: 3rem 0 0rem 0;
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
    color: #144D79;
text-shadow: 0px 3px 6px #00000029;
border-bottom: 4px solid #EE6060;
  }
  .all_product_headings h5{
    font-size: 1rem;
    width: 100%;
  }
  .box_products{
    position: relative;
  }
  .product_img_box{
    width:100%;
    height:18rem;
    margin-top: .5rem;
  }
  .box_products2{
    position: relative;
  }
  .product_img_box2{
    width:100%;
    height:18rem;
    margin-top: .5rem;
  }
  .all_product_services_box{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top: 15%;
    left: 2%;
  background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url(/static/media/1.29408eb3.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
    /* background-color:rgba(0,0,0,0.78); */
    /* opacity: .66; */
  }
  .all_product_services_box2{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top:15%;
    left: 10%;
    background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url(/static/media/4.ca9139ee.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-color:rgba(0,0,0,0.78); */
    /* opacity: .66; */
  }
  .all_product_services_box3{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top: 15%;
    left: 2%;
  background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url(/static/media/3.7228d65c.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  }
  .all_product_services_box4{
    width: 29.2rem;
    height: 15rem;
    position: absolute;
    top:15%;
    left: 10%;
    background-image:linear-gradient(rgba(0,0,0,0.38)0%, rgba(0,0,0,0.38)100%), url(/static/media/2.1472659a.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .all_product_services_box h3,
  .all_product_services_box2 h3,
  .all_product_services_box3 h3,
  .all_product_services_box4 h3
 {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    padding-top:7rem;
    text-transform: capitalize;
    font-weight: bold;
  }
  .all_product_services_box p,
  .all_product_services_box2 p,
  .all_product_services_box3 p,
  .all_product_services_box4 p
 {
    font-size: .8rem;
    color: #fff;
    text-align: center;
    
  }
  .btn_product_read{
    background: none;
    border: none;
    margin-left: 13rem;
    font-size: .8rem;
    color: #ED1A3B;
text-transform: uppercase;
opacity: 1;
font-weight: bold;
  }
  .btn_product_read1{
    background: none;
    border: none;
    margin-right: 12rem;
    font-size: .8rem;
    color: #ED1A3B;
text-transform: uppercase;
font-weight: bold;
opacity: 1;
  }
  @media screen and (max-width:800px){
    .product_top_section {
      height: 15rem;
    }
    .product_top_heading {
      position: relative;
    }
    .product_top_heading h1 {
      padding: 4rem 0 0 1rem;
    }
    .indroducting_heading{
      padding: 3rem 0 0rem 0;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      text-align: center;
      color: #144D79;
text-shadow: 0px 3px 6px #00000029;
border-bottom: 4px solid #EE6060;
    }
    .all_product_headings h5{
      font-size: 1rem;
      width: 100%;
    }
    .box_products{
      position: relative;
    }
    .box_products2{
      position: relative;
    }
  
    .product_img_box{
      width:16rem;
      height:12rem;
      margin-top: .5rem;
    }
    .product_img_box2{
      width:11rem;
      height:12rem;
      margin-top: .5rem;
    }
    .all_product_services_box{
      width: 14.5rem;
      height: 9.5rem;
      top: 15%;
      left: 1%;
     
    }
    .all_product_services_box3{
      width: 14.5rem;
      height: 9.5rem;
      top: 15%;
      left: 1%;
     
    }
    .all_product_services_box5{
      width: 14.5rem;
      height: 9.5rem;
      top: 15%;
      left: 1%;
     
    }
    .all_product_services_box2{
      width: 17rem;
      height: 12rem;
      top: 4%;
      left: -60%;
      background-color:rgba(0,0,0,0.68);
      /* opacity: .66; */
    }
    .all_product_services_box4{
      width: 17rem;
      height: 12rem;
      top: 4%;
      left: -60%;
      background-color:rgba(0,0,0,0.68);
      /* opacity: .66; */
    }
    .all_product_services_box6{
      width: 17rem;
      height: 12rem;
      top: 4%;
      left: -60%;
      background-color:rgba(0,0,0,0.68);
      /* opacity: .66; */
    }
    .dot{
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background:#fff;
      margin: 2rem 0 0 7rem;
    }
    .dot1{
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background:#fff;
      margin: 2rem 6rem 0 0;
    }
    .all_product_services_box h3,
    .all_product_services_box2 h3,
    .all_product_services_box3 h3,
    .all_product_services_box4 h3
    {
      font-size: 1rem;
      color: #fff;
      text-align: center;
      padding-top:3rem;
      text-transform: capitalize;
    }
    .all_product_services_box p,
  .all_product_services_box2 p,
  .all_product_services_box3 p,
  .all_product_services_box4 p
  {
      font-size: .6rem;
      color: #fff;
      text-align: center;
      padding: 0 .1rem 0 .1rem;
      
    }
    .btn_product_read{
      background: none;
      border: none;
      margin-left:5rem;
      font-size: .8rem;
      color: #ED1A3B;
  text-transform: uppercase;
  opacity: 1;
    }
    .btn_product_read1{
      background: none;
      border: none;
      margin-right: 5rem;
      font-size: .8rem;
      color: #ED1A3B;
  text-transform: uppercase;
  opacity: 1;
    }
  }
.main_bg {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  opacity: 1;
}
.services_top_section {
  position: relative;
  background-image: url(/static/media/services22.be560713.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 26rem;
  width: 100%;
}
.services_top_heading {
  position: relative;
}
.services_top_heading h1 {
  color: #2c2024;
  text-align: center;
  padding: 8rem 0 0 1rem;
  opacity: 1;
}
.navlink_homeandcontact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.home_and_service p {
  display: inline-block;
  text-align: center;
  color: #2c2024;
}
.home_and_service p:hover {
  color: #144d79;
  font-size: 1.1rem;
}
/* iot css section start here */
.services_header_section {
  margin-top: 5rem;
}
.iot_box {
  position: relative;
}

.iot_top_img {
  position: relative;
  width: 100%;
  height: 26rem;
  margin: 1rem 0 0 0rem;
}

.btn_get_service {
  position: absolute;
  top: 88%;
  left: 27%;
  transform: translate(-50%, -50%);
  /* margin: 2rem 0 0 3rem; */
  font-size: 0.8rem;
  padding: 10px 25px;
  background: #144d79 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 50px #0000001a;
  border-radius: 100px;
  color: #fff;
  outline: none !important;
  border: none;
  opacity: 1;
  text-decoration: none !important;
  font-weight: bold;
}
.btn_get_service:hover {
    background: #fff;
    color:#144d79 ;
    transition: .3s ease-in;
  
  }

.iot_headings {
  margin-top: 0rem;
}
.iot_headings h3 {
  width: 70%;
  color: #144d79;
  font-size: 1.6rem;
  border-bottom: 4px solid #ee6060;
}
.iot_headings h4 {
  padding-top: 1rem;
  color: #2c2024;
  font-size: 1.2rem;
  line-height: 1.5;
}
.iot_headings p {
  padding-top: 1rem;
  color: #707070;
  line-height: 2;
}
/* cloud section start here */
.iot_box2 {
  position: relative;
  /* margin-left: 8rem;
    width: 20rem;
    height: 26rem;
    background: transparent linear-gradient(180deg, #D40808 0%, #6A0404 100%) 0% 0% no-repeat padding-box;
box-shadow: 6px 6px 20px #00000029;
border-radius: 55px;
opacity: 0.85; */
}

.iot_top_img2 {
  position: relative;
  width: 100%;
  height: 26rem;
  margin: 1rem 0 0 0rem;
}
.btn_get_service2 {
  position: absolute;
  top: 88%;
  left: 72.5%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  padding: 10px 25px;
  background: #144d79 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 50px #0000001a;
  border-radius: 100px;
  color: #fff;
  outline: none !important;
  border: none;
  opacity: 1;
  text-decoration: none !important;
  font-weight: bold;
}
.btn_get_service2:hover {
    background: #fff;
    color:#144d79 ;
    transition: .3s ease-in;
  
  }
.cloud_headings {
  margin-top: 0rem;
}
.cloud_headings h3 {
  width: 80%;
  color: #144d79;
  font-size: 1.6rem;
  border-bottom: 4px solid #ee6060;
}
.cloud_headings h4 {
  padding-top: 1rem;
  color: #2c2024;
  font-size: 1.2rem;
  line-height: 1.5;
}
.cloud_headings p {
  padding-top: 1rem;
  color: #707070;
  line-height: 2;
}
@media screen and (max-width: 800px) {
  .services_top_section {
    height: 13rem;
  }
  .services_top_heading h1 {
    padding: 4rem 0 0 1rem;
  }
  /* iot css section start here */
  .services_header_section {
    margin-top: 2rem;
  }
  .iot_top_img {
    width: 100%;
    height: 20rem;
    margin: 1rem 0rem 0 0rem;
  }

  .btn_get_service {
    position: absolute;
    top: 88%;
    left: 35%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
  }
  .iot_headings h3 {
    font-size: 1.2rem;
  }
  /* cloud section start here */

  .iot_top_img2 {
    width: 100%;
    height: 20rem;
    margin: 1rem 0rem 0 0rem;
  }

  .btn_get_service2 {
    top: 88%;
    left: 64%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    width: 9.5rem;
    padding: 10px 25px !important;
  }

  .cloud_headings h3 {
    width: 80%;
    color: #144d79;
    font-size: 1.2rem;
    border-bottom: 4px solid #ee6060;
  }
  .cloud_headings h4 {
    padding-top: 1rem;
    color: #2c2024;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  .cloud_headings p {
    padding-top: 1rem;
    color: #707070;
    line-height: 2;
  }
}


.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 35px #0000000D;
opacity: 1;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .menu_active {
    font-size: bold;
    border-bottom: 2px solid #565387 !important;
  }
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #147944;
    justify-self: start;
    margin-left: 30px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
  .logo_img{
    width: 8rem;
    height: 3.5rem;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width:80vw;
    justify-content:end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 40px;
    text-decoration: none;
  }
  
  .nav-links {
    color: #212121 !important;
    display: flex;
    align-items: center;
    align-items:space-between;
    text-decoration: none !important;
    padding:0.5rem 1rem;
    height: 100%;
    font-size: 1rem !important;
  }
  .dropdown_color{
    color: #212121 !important;
    display: flex;
    align-items: center ;
    align-items:space-between;
    text-decoration: none !important;
    padding:0.5rem 1rem !important;
    height: 100%;
    font-size: 1rem !important;
  }
  .nav-links:hover {
    color: #207181 !important;
    border-bottom: 2px solid #207181 !important;
    transition: all 0.2s ease-out;
  }
  .dropdown_color:hover{
    color: #207181 !important;
    border-bottom: 2px solid #207181 !important;
    transition: all 0.2s ease-out;
  }
  .fa-bars {
    color: #fff !important;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .navbar {
     background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
   
      }
    .NavbarItems {
      position: relative;
    }
    .logo_img{
      width: 7rem;
      height: 3rem;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item {
      margin-top: 1rem;
      height: 40px;
      /* border: 1px solid red; */
    
    }
    .nav-links {
      text-align: center;
      padding: .8rem !important;
      width: 100%;
      display: table;
      color:#fff !important;
      font-size: 1.3rem !important;
      font-family: var(--Roboto);
      margin-top: 0rem;
     

    }
  .dropdown_color{
    text-align: center !important;
    padding-bottom: 0rem !important;
    width: 100%;
    display: table;
    color:#fff !important;
    border: none !important;
    font-size: 1rem !important;
    background-color:none !important;
    -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }
  .dropdown_color:hover {
    color: #242424 !important;
    border-bottom:none !important;
    border: none !important;
    background-color: #fff;
}
    .nav-links:hover {
      background-color: #fff;
      color: #242424 !important;
      border-radius: 0;
    }
    .navbar-logo {
      margin-left: 10px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 15px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: .5rem 0;
      margin: .125rem 0 0 3.5rem !important;
      font-size: .8rem !important;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: .25rem;
  }
 
  }
.main_container{
    overflow-x: hidden;
}
.footer_background{
    background: #E4E4E4 0% 0% no-repeat padding-box;
    width: 100%;
}
.footer_left1 img{
    width:7rem ;
    height: 6rem;
    margin-left: 4rem;
}
.footer_left1 p{
font-size: .8rem;
padding-top: .5rem;
}
.footer_left2,
.footer_center,
.footer_right1,
.footer_right2{
    margin-top: 5rem;
}
.footer_left2 h3,
.footer_center h3,
.footer_right1 h3,
.footer_right2 h3{
    font-size: 1.2rem;
    color: #2C2024;
}
.footer_left2 p,
.footer_center p,
.footer_right1 p,
.footer_right2 p{
    font-size: 1rem;
    color: #707070 !important;
    text-decoration: none;
}
.footer_left2_headings{
    font-size: 1rem;
    color: #707070 !important;
    text-decoration: none;
}
.circile_media{
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #333;


}
.footer_circle_icons{
    color: #fff;
    margin: .7rem;
    height: 1.2rem;
    width: 1.2rem;
}
.footer_circle_icons2{
    color: #fff;
    margin: .5rem;
    height: 1.2rem;
    width: 1.2rem;
}
.footer_circle_icons:hover{
    color: #144d79;
    margin: .7rem;
    height: 1.4rem;
    width: 1.4rem;
}
/* second footer css images */
.footer_img_section{
    margin:2rem 0 2rem 0;
}
.footer_images{
    width: 7rem;
    height: 4rem;
}
.all_policy{
    /* margin: 0rem 0 2rem 0; */
}
.all_policy h3{
    padding-top: 1rem;
    font-size: 1rem;

}
.all_policy h5{
    display: inline-block;
    float: right;
    font-size: 1rem;
    padding:0 .8rem 2rem .8rem;
}
@media screen and (max-width:800px){
    .footer_left1 img{
        width:6rem ;
        height: 5rem;
        margin-left: 6rem;
    } 
    .footer_left1 p{
        text-align: center;
        font-size: .9rem;
        padding-top: .5rem;
        }
        .footer_left2,
.footer_center,
.footer_right1,
.footer_right2{
    margin-top: 2rem;
} 
.footer_left2 h3,
.footer_center h3,
.footer_right1 h3,
.footer_right2 h3{
    font-size: 1rem;
  
}
.footer_left2 p,
.footer_center p,
.footer_right1 p,
.footer_right2 p{
    font-size: .8rem;
}
.footer_img_section{
    margin:1rem 0 1rem 0;
}
.footer_images{
    width: 6rem;
    height: 3rem;
} 
.all_policy h3{
    text-align: center;
    font-size: .8rem;
}
.all_policy h5{
    float:none;
    text-align: center;  
    font-size: .8rem;
}
.circile_media{
    width: 2.1rem;
    height: 2.1rem;
    margin: .1rem;
}
.media_margin{
    margin-left: .3rem;
}
.footer_circle_icons{   
    margin: .5rem;
    height: 1.1rem;
    width: 1.1rem;
}
.footer_circle_icons2{
    margin: .3rem;
    height: 1rem;
    width: 1rem;
}
.special_center{
    text-align: center;
}
}
