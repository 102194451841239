.about_top_section {
  position: relative;
  background-image: url("../../images/p6.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 30rem;
  width: 100%;
}
.about_top_heading {
  position: relative;
}
.about_top_heading h1 {
  color: #fff;
  text-align: center;
  padding: 8rem 0 0 1rem;
  opacity: 1;
}
.navlink_homeandcontact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.home_and_about p {
  display: inline-block;
  text-align: center;
  color: #fff !important;
}
.home_and_about p:hover {
  color: #fff !important;
  font-size: 1.1rem;
}
.digial_future {
  margin-top: 5rem;
}
.digial_future h4 {
  color: #c2c2c2;
  font-size: 1rem;
  padding: 1rem 0 0 0;
}
.digial_future h3 {
  color: #ffffff;
  font-size: 1.8rem;
}
.digial_future p {
  color: #ffffff;
  font-size: 0.8rem;
  padding-top: 2rem;
}

/* seconde part csss */

.stort_heading,
.vision_heading {
  position: relative;
  margin-top: 8rem;
}
.stort_heading h2 {
  color: #144d79;
  border-bottom: 4px solid #ee6060;
  width: 75%;
}
.stort_heading p,
.vision_heading p {
  color: #707070;
  padding: 1rem 0 2rem 0;
}
.btn_explore_more {
  font-size: 0.8rem;
  padding: 10px 25px;
  background: #144d79 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 50px #0000001a;
  border-radius: 100px;
  color: #fff;
  outline: none !important;
  border: none;
  text-decoration: none !important;
  font-weight: bold;
  opacity: 1;
}
.btn_explore_more:hover {
 
  color: #144d79;
  background: #fff;
  transition: .4s ease-in;
  border: 1px solid #144d79;

}

.story_video iframe{
  width:100%;
  height:20rem;
  margin-top: 5rem;
}
/* third section css */
.vision_heading h2 {
  color: #144d79;
  border-bottom: 4px solid #ee6060;
  width: 33%;
}
.header_top_img1 {
  margin-top: 5rem;
  width: 100%;
  height: 80%;
}
/* values section css */
.value_about_heading h2 {
  padding-top: 5rem;
    color: #144d79;
    border-bottom: 4px solid #ee6060;
    width: 28%;
  }
  .value_about_heading p {
    color: #707070;
    padding: 1rem 0 2rem 0;
  }
  /* .respect_box1{
    display: flex;
    justify-content:space-around;
    align-items: center;
    flex-direction: row;
    margin-top: 1rem;
    width:80%;
   max-height:60%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 15px #00000029;
border-radius: 34px;
opacity: 1;
}
.repect_onecol{
  display: flex;
  align-items: center;
 
  flex-direction: column;
}
.first_img img{
    display: inline-block;
    width: 3rem;
    height: 3rem;
   
}
.first_img p{
    display: inline-block;
    font-size: 1rem;
    color: #333;
    padding-left: .5rem;
}
.btn_round{
    margin-top: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50% !important;
   color:#fff;
   border: 2px solid #fff !important;
    background: #ec615b !important;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
}
.card_heading p{
    color: #333;
    font-size: 1.2rem;
}
.myperafirst{
    color: #333;
    font-size: 1.2rem;
} */

/* ----------------------------new values css start here-------------------------------- */
/* .my_new_values{
margin: 5rem 0 10rem 0;
  
} */
/* .new_values_container{
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
.accordion-item{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 34px;
  opacity: 1;
  border-radius: .4rem;
  margin-bottom: 1rem;
  padding: 1rem;
  

}
.accordion-link{
  font-size: 1.6rem;
  color: rgba(255,255,255,.5);
  text-decoration: none;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.accordion-link .ion-md-add, .ion-md-remove{
  color: #333;
  
}
.accordion-link .ion-md-remove{
  display: none;
}
.answer{
  max-height: 0;
  overflow: hidden;
  position: relative;
 
  background-color: #fff;
  transition:max-height 650ms;
}
.answer::before{
content: "";
  position: absolute;
  width: .6rem;
  height: 90%;
  background-color: #ec615b;
  top: 50%;
  left: 0;
  transform: translate(-50%,-50%);
 
}
.answer p{
  padding: 1rem;
  font-size: 1rem;
  color: #333;
}
.accordion-item:target .answer{
  max-height: 20%;

}
.accordion-item:target .accordion-link .ion-md-add {
  display: none;
}
.accordion-item:target .accordion-link .ion-md-remove {
  display: block;
}
.img_values_all{
  width: 3rem;
  height: 3rem;
}
.ion-md-add {
  display: inline-block;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #ec615b;
  color: #fff;
  
}
 */

 /* 3rd effect css */
.container{
  width: 100%;
  max-width: 480px;
  margin: auto;
}
.accordion{
  width: 100%;
  max-width: 540px;
  margin: 15px auto;
  color: #333;
}
.accordionHeading{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius:34px 34px 0 0;
  padding: 14px 0;
}
.accordionHeading .container{
display: flex;
align-items: center;
justify-content: space-between;
}
.accordionHeading p{
  letter-spacing: 1.2px;
  font-weight: 600;
}
.accordionHeading span{
  cursor: pointer;
}
.accordionContent{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius:0 0 34px 34px;
  height: 0;
  opacity: 0;
  display: flex;
  overflow: hidden;
  
  transition: all 600ms ease-in-out;
}
.accordionContent.show{

  height: 180px;
  opacity: 1;
 
}
.accordionContent p{
  padding-top: .5rem;
  font-size: 15px;
  line-height: 1.6;
}
.animated{
  width: 3rem;
  height: 3rem;
  margin:.2rem 2rem .2rem 1rem;
}
.icon_add_bg{
  width: 3rem;
  height: 3rem;
  display: inline-block;
  border-radius: 50%;
  background-color: #ec615b;
}
.icon_add{
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  margin: .8rem;
}
.icon_remove{
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  margin: .8rem;
}
/* what we get section */
.we_get_section{
    margin-top: 3rem;
    background: #F9FFDC 0% 0% no-repeat padding-box;
}
.get_heading h2 {
  padding-top: 2rem;
    color: #144d79;
    border-bottom: 4px solid #ee6060;
    width: 28%;
  }
  .get_heading p {
    color: #707070;
    padding: 1rem 0 2rem 0;
  }
  .benefit_heading h3{
    color: #2C2024;
    font-size: 1.5rem;
    text-align: center;
  }
  .benefit_heading p{
    color: #707070;
    padding: .5rem 0;

 
  }
  .gift_and_heart_img{
    width: 3rem;
    height: 3rem;
  }
  /* our people css start here */
  .our_people_section{
    margin-top: 3rem;
}
.our_people_heading h2 {
    color: #144d79;
    border-bottom: 4px solid #ee6060;
    width: 28%;
  }
  .our_people_heading p {
    color: #707070;
    padding: 1rem 0 2rem 0;
  }
  .people_box1{
    position: relative;
      height: 16rem;
      width: 100%;
      background: transparent linear-gradient(180deg, #8182EE 0%, #2d516d 100%) 0% 0% no-repeat padding-box;
      box-shadow: 6px 6px 20px #00000029;
      border-radius: 55px;
      opacity: 0.85; 
      /* background-image: url('../../images/cover.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat; */
    border-radius: 6px;
    opacity: 1;
    margin-bottom: 1.5rem;
  }
  .circle_team_img{
    position: absolute;
    display: inline-block;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }
  .circle_team_img2{
    background: #fff;
    position: absolute;
    display: inline-block;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }
  .people_box1 h3{
    padding-top: 12rem;
    font-size: 1.2rem;
    color: #fff;
    text-shadow: 2px 2px 2px #00000029;
    text-align: center;
 
}
.people_box1 h4{
  font-size: .8rem;
  color: #fff;
  text-align: center;

}
 


@media screen and (max-width:800px){
  .about_top_section {
    position: relative;
    background-image: url("../../images/p6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 17rem;
    
  }
  .about_top_heading h1 {
    color: #fff;
    text-align: center;
    padding: 4rem 0 0 1rem;
    opacity: 1;
  }
  .digial_future {
    margin-top: 0rem !important;
  }
  .digial_future h4 {
    font-size: 1rem;
    padding: .5rem 0 0 0;
  }
  .digial_future h3 {
    font-size: 1.3rem !important;
  }
  .digial_future p {
    font-size: 0.8rem;
    padding-top: 0rem !important;
  }
/* seconde part csss */

.stort_heading,
.vision_heading {
  margin-top: 3rem;
  position: relative;
}
.stort_heading h2 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
 font-size: 1.5rem;
  width: 90%;
  text-align: center;
}
.stort_heading p,
.vision_heading p {
  padding: 3rem 0 1rem 0;
}
.header_top_img1 {
  margin-top: 0rem;
  width: 100%;
  height: 70%;
}
.story_video iframe{
  width:100%;
  height:17rem;
  margin-top: 2rem;
}
/* third section css */
.vision_heading {
  position: relative;
}
.vision_heading h2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 70%;
}
/* values section css */
.value_about_heading h2 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 80%;
padding-top: 4rem;
}
.value_about_heading p {
  color: #707070;
  padding: 4.5rem 0 2rem 0;
}

  /* respect section css */
.respect_box1{
  
    margin-top: 0rem !important;
    width:100%;
    max-height:10rem;
    margin-bottom: 1rem !important;
}
.first_img img{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-bottom: 0rem;
  padding-right: 0;
}

.card_heading p{

    color: #333;
    font-size: .8rem;
    padding-top: 0rem;
    
}
.myperafirst{
    color: #333;
    font-size: .8rem;
    padding-top: 0rem;
}
.btn_round{
  margin-top: 0rem;
  width: 3rem;
  height: 3rem;
  margin-bottom: 2rem;
 
}
.respect_margin{
  margin: 0;
}

/* what we get section */
.we_get_section{
  margin-top: 0;
}
.get_heading h2 {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 80%;
}
.get_heading p {

  padding: 5rem 0 1rem 0;
}
.benefit_heading h3{
  text-align: left;
}
.benefit_heading p{
  padding: .5rem 0;
}
 /* our people css start here */
 .our_people_section{
  margin-top: 1rem;
}
.our_people_heading h2 {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.5rem;
   width: 80%;
}
.our_people_heading p {
  padding: 3rem 0 1rem 0;
}
/* .people_box h3{
  padding-top: 9.5rem;
  

} */
.sape_about_pepople{
  width: 10rem;

}

.people_box1{
  position: relative;
    height: 16rem;
 
}
.circle_team_img{
  position: absolute;
  display: inline-block;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.people_box1 h3{
  padding-top: 10rem;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;

}
.people_box1 h4{
font-size: .8rem;
color: #fff;
text-align: center;

}

}