.career_top_section2 {
    position: relative;
    background-image: url("../../images/careers2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 32rem;
  
  }
  .career_top_heading2 {
    position: relative;
  }
  .career_top_heading2 h1 {
    color: #2C2024;
    text-shadow: 5px 3px 6px #00000029;
    opacity: 1;
    text-align: center;
    padding: 8rem 0 0 1rem;
    opacity: 1;
  }
  .navlink_homeandcontact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .home_and_career2 p {
    text-shadow: 5px 3px 6px #00000029;
    display: inline-block;
    text-align: center;
    color: #2C2024;
  }
  .home_and_career2 p:hover {
    text-shadow: 5px 3px 6px #00000029;
    color: #2C2024;
    font-size: 1.1rem;
  }
  .career_heading h5{
     
      text-align: center;
      padding-top: 3rem;
      font-size: 1rem;
      border-bottom: 4px solid #FF0000 !important;
  }

  .we_hiring {
    margin-top: 0rem;
  }
  .we_hiring h4 {
    color: #8182EE;
    font-size: 1rem;
    padding: 1rem 0 0 0;
  }
  .we_hiring h3 {
    color: #2d516d;
text-shadow: 0px 3px 6px #00000029;
opacity: 1;
    font-size: 1.8rem;
  }
 .btn_hover_effect{
   margin-top: 2rem;
  font-size: 0.8rem;
  padding: 10px 35px;
  background: #144d79 0% 0% no-repeat padding-box;
  box-shadow: 10px 10px 50px #0000001a;
  border-radius: 100px;
  color: #fff;
  outline: none !important;
  font-weight: bold !important;

  border: none;
  opacity: 1;
 
 }
 .btn_hover_effect:hover{
background: #fff;
color: #144d79;
border: 1px solid #144d79;
transition: .4s ease-in;

}
 /* form section css start */
 
 .form_section2{
  margin-top: 2rem;
  position: relative;
  margin-left: 5rem;
  width: 22rem;
  height: 26rem;
  border-radius: 18px;
  background: #FEF6F6 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 20px #00000029;
opacity: 0.65;
}
.form_section2 h4{
  font-size: 1.1rem;
  padding: 2rem 0 .5rem 5rem;
  color: #2C2024;

}
.form_section2 p{
  font-size: .8rem;
  padding: .2rem 0 .5rem 5rem;
  color: #706B70;

}
.form_section_div{
  margin: 1rem 0 0 5rem;
}
.contact_career_box{
  position: absolute;
  top: 50%;
  left: -3%;
  transform: translate(-50%, -50%);
  background: #144D79 0% 0% no-repeat padding-box;
box-shadow: 6px 6px 20px #00000029;
border-radius: 25px;
opacity: 0.67;
width: 9rem;
height: 10rem;
}
.contact_career_box h1{
  padding: 1rem 0 0 0;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 2.6px;
color: #FFFFFF;
opacity: 1;

}
.form-control {
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: 1rem .75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* color: #495057; */
  color: #fff !important;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: none !important;
  outline: none !important;
  border-radius: 21px  !important;
  width: 19rem !important;
  background: #6E6978 0% 0% no-repeat padding-box !important;
border-radius: 21px;
opacity: 1;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#form_2{
  width: 15rem !important;
}
#exampleFormControlInput11{
  width: 15rem !important;
}
#exampleFormControlTextarea12{
  width: 15rem !important;
}
::placeholder {
  color:#fff !important;
padding: 1rem 1rem !important;
}
.btn_submit2{
  outline: none !important;
  border: none !important;
  padding: 10px 25px !important;
  font-size: .8rem !important;
  color: #fff;
 border-radius:18px !important;
 box-shadow: 6px 6px 20px #00000029 !important;
 background: #144D79 0% 0% no-repeat padding-box !important;
}
.men_icon2{
  position: absolute;
  top: 93%;
  left: 17%;
 
  transform: translate(-50%, -50%);
}
.men_img_bottom2{
  position: relative;
  z-index:999;
  width: 3.5rem;
  height: 3.5rem;
}
.form_input_man2{
 z-index: 1;
  position: absolute;
  top: 32.5%;
  left: 80%;
  transform: translate(-50%, -50%);
}
.user_img_bottom2{
  color:#fff !important;
  width: 1rem;
  height: 1rem;
}
.email_icon2{
 
   position: absolute;
   top: 45.5%;
   left: 80%;
   transform: translate(-50%, -50%);
}
.email_img_bottom2{
  
   width: 1rem;
   height: 1rem;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  /* background-color: rgb(232, 240, 254) !important; */
  background-color: #6E6978 0% 0% no-repeat padding-box !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}
.my_lists{
   font-size: .8rem;
   text-align: center;
   color: #fff;
   text-decoration: none !important;
   padding:0 0 .4rem 0;
}


 /* form section css end here */
 /* top section end here */
  
  /* who we are section */
  .whowe_blog_section{
      position: relative;
  }
  .who_we_top_img{
      position: relative;
    
      margin-top: 5rem;
      margin-bottom: 0;
      width: 90%;
      height: 20rem;
  }
  .whowe_headings {
      margin-top: 4rem;
  }
  .whowe_headings h2{
    border-bottom: 4px solid #EE6060;
    width: 60%;
      padding: 5rem 0  1rem 0;
font-size: 1.8rem;
color: #144D79;
  }
  .whowe_headings p{
    font-size: 1.1rem;
    color: #707070;
      }
      .all_circle2{
        text-decoration: none;
         position: absolute;
         top: 75%;
         left: 5%;
         transform: translate(-50%, -50%);
         width: 8rem;
         height: 8rem;
         border-radius: 50%;
      
         background: #D5BED145 0% 0% no-repeat padding-box;
         border: 1px solid #7070700D;
         opacity: 1;
     }
     .all_circle3{
        text-decoration: none;
         position: absolute;
         top: 20%;
         left: 87%;
         transform: translate(-50%, -50%);
         width: 8rem;
         height: 8rem;
         border-radius: 50%;
      
         background: #D5BED145 0% 0% no-repeat padding-box;
         border: 1px solid #7070700D;
         opacity: 1;
     }
     /* work section css start here */
.work_with_us h2{
  border-bottom: 4px solid #EE6060;
  width:25%;
    padding: 5rem 0  1rem 0;
font-size: 1.8rem;
color: #144D79;
}
/* work career second section */
.work2_section{
  margin-top: 4rem;
}
.dot_background{
  background-image: url('../../images/grid.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.all_careers_options{
  margin: 0rem 0 1rem 0rem;
  background-color: #144D79;
  width: 10rem;
  height: 6.5rem;
}
.all_careers_options h4{
  color: #FFF7F7;
  font-size: .9rem;
  text-align: center;
  padding: 2rem .4rem 0 .4rem;
}
.responsibilites_headings h3{
  padding: 1.5rem 0 .5rem 0;
  color: #2C2024;
  font-size: 1.4rem;
}
.responsibilites_headings p{

  color: #2C2024;
  font-size: .9rem;
line-height: 2;
}
.work_images{
  margin-top: 8rem;
  width:100%;
  height: 25rem;
}
.contact_to_hr{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  background: #207181 0% 0% no-repeat padding-box;
border: 1px solid #707070;
opacity: 0.4;
}
.hr_box a{
  text-decoration: none;
  font-size: 2rem;
  /* color: #144D79; */
  color: #fff;
}
    @media screen and (max-width:800px){
      .career_top_section2 {
      
        height: 18rem;
        width: 100%;
      
      }
      .career_top_heading2 h1 {
       
        padding: 1rem 0 0 1rem;
        
      }
      .we_hiring {
        margin-top: 0rem;
      }
      .we_hiring h4 {
        color: #8182EE;
        font-size: .9rem;
        padding: 1rem 0 0 0;
      }
      .we_hiring h3 {
        color: #2d516d;
    text-shadow: 0px 3px 6px #00000029;
    opacity: 1;
        font-size: .8rem;
      }
     .btn_hover_effect{
       margin-top: 1rem;
      font-size: 0.8rem;
      padding: 8px 35px;
      background: #144d79 0% 0% no-repeat padding-box;
      box-shadow: 10px 10px 50px #0000001a;
      border-radius: 100px;
      color: #fff;
      outline: none !important;
      border: none;
      opacity: 1;
     
     }

      /* form section css here */
      .form_section2{
        margin-top: 7rem;
        position: relative;
        margin-left: 0;
        width: 100%;
        height: 26rem;
    opacity: 0.95;
    }
    .form_section2 h4{
        padding: 2rem 0 .5rem 2rem;
    }
    .form_section2 p{
        padding: .2rem 0 .5rem 2rem;
        color: #706B70;
    }
    .form_section_div{
        margin: 1rem 0 0 1rem;
    }
    
    .contact_career_box{
        top: -13%;
        left: 50%;
    opacity: 0.67;
    width: 13rem;
    height: 9rem;
    }
    .form-control {
      width: 18rem !important;
    }
    .form_input_man2{
         top: 32%;
         left: 82%;
        
     }
     .email_icon2{
        top: 45%;
        left: 82%;
    }
    .men_icon2{
        top: 94%;
        left: 9%;
    }
    .men_img_bottom2{
        position: relative;
        z-index:999;
        width: 3rem;
        height: 3rem;
    }
    #form_2{
      width: 95% !important;
    }
    #exampleFormControlInput11{
      width: 95% !important;
    }
    #exampleFormControlTextarea12{
      width: 95% !important;
    }


  /* who we are section */

.who_we_top_img{
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 17rem;
}
.whowe_headings {
    margin-top: 1rem;
}
.whowe_headings h2{
    padding: 0rem 0  1rem 0;
font-size: 1.3rem;

}
.whowe_headings p{
  font-size: 1rem;
 
    }
    .work_with_us h2{
      border-bottom: 4px solid #EE6060;
      width:48%;
        padding: 1rem 0  0rem 0;
    font-size: 1.3rem;
    color: #144D79;
    }
    .work2_section{
      margin-top: 1rem;
    }
    .all_careers_options{
      margin: 0rem 0rem 0rem 0rem;
      width: 6rem;
      height: 6rem;
    }
    .all_careers_options h4{
      color: #FFF7F7;
      font-size: .9rem;
      text-align: center;
      padding: 2rem .4rem 0 .4rem;
    }
    .responsibilites_headings h3{
      padding: 1rem 0 .5rem 0;
      color: #2C2024;
      font-size: 1.2rem;
    }
    .responsibilites_headings p{
    
      color: #2C2024;
      font-size: .8rem;
    line-height: 1.5;
    }
    .work_images{
      margin-top: 0rem;
      width:100%;
      height: 20rem;
    }
    .contact_to_hr{
      height: 6rem;
    }
    .hr_box a{

      font-size: 1rem;
      
    }
    }