
.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 10px 10px 35px #0000000D;
opacity: 1;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .menu_active {
    font-size: bold;
    border-bottom: 2px solid #565387 !important;
  }
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #147944;
    justify-self: start;
    margin-left: 30px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
  .logo_img{
    width: 8rem;
    height: 3.5rem;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width:80vw;
    justify-content:end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 40px;
    text-decoration: none;
  }
  
  .nav-links {
    color: #212121 !important;
    display: flex;
    align-items: center;
    align-items:space-between;
    text-decoration: none !important;
    padding:0.5rem 1rem;
    height: 100%;
    font-size: 1rem !important;
  }
  .dropdown_color{
    color: #212121 !important;
    display: flex;
    align-items: center ;
    align-items:space-between;
    text-decoration: none !important;
    padding:0.5rem 1rem !important;
    height: 100%;
    font-size: 1rem !important;
  }
  .nav-links:hover {
    color: #207181 !important;
    border-bottom: 2px solid #207181 !important;
    transition: all 0.2s ease-out;
  }
  .dropdown_color:hover{
    color: #207181 !important;
    border-bottom: 2px solid #207181 !important;
    transition: all 0.2s ease-out;
  }
  .fa-bars {
    color: #fff !important;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .navbar {
     background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
   
      }
    .NavbarItems {
      position: relative;
    }
    .logo_img{
      width: 7rem;
      height: 3rem;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item {
      margin-top: 1rem;
      height: 40px;
      /* border: 1px solid red; */
    
    }
    .nav-links {
      text-align: center;
      padding: .8rem !important;
      width: 100%;
      display: table;
      color:#fff !important;
      font-size: 1.3rem !important;
      font-family: var(--Roboto);
      margin-top: 0rem;
     

    }
  .dropdown_color{
    text-align: center !important;
    padding-bottom: 0rem !important;
    width: 100%;
    display: table;
    color:#fff !important;
    border: none !important;
    font-size: 1rem !important;
    background-color:none !important;
    -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }
  .dropdown_color:hover {
    color: #242424 !important;
    border-bottom:none !important;
    border: none !important;
    background-color: #fff;
}
    .nav-links:hover {
      background-color: #fff;
      color: #242424 !important;
      border-radius: 0;
    }
    .navbar-logo {
      margin-left: 10px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 15px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: .5rem 0;
      margin: .125rem 0 0 3.5rem !important;
      font-size: .8rem !important;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.15);
      border-radius: .25rem;
  }
 
  }